import React, { useState, useEffect } from 'react'
import { Table, Tag, Button, Drawer, Input } from 'antd'
import { FiCreditCard, FiEye } from 'react-icons/fi'
import SectionTitle from '../../components/AllInApp/section-title'
import RefundBooking from '../../common/RefundBooking'
import ViewBooking from '../../components/AllInApp/Booking/View'
import CreateBooking from '../../components/AllInApp/Booking/Create'
import { timeIntervals, getDateFormat } from '../../utils/'
import apiClient from '../../services/axios'
import ApiConfig from '../../services/ApiConfig'

const BookingComponent = () => {
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false);
  // const [pageSize, setPageSize] = useState(10);
  const [bookingListRes, setbookingListRes] = useState(null);
  const [filteredBooking, setfilteredBooking] = useState(null);
  const [facilityListRes, setfacilityListRes] = useState(null);
  const [typeListRes, settypeListRes] = useState(null);
  const [playerListRes, setplayerListRes] = useState(null);
  const [cVisible, setcVisible] = useState(false);
  const [bVisible, setbVisible] = useState(false);
  const [selectedItem, setselectedItem] = useState(null);
  const [createNewBooking,setcreateNewBooking] = useState(false)
  const [selectedPaymentDetails, setselectedPaymentDetails] = useState(null);
  const [showModal, setshowModal] = useState(false);
  const [search, setSearch] = useState('');


  useEffect(() => {
    if (search !== '') {
      const getBookingId = bookingListRes?.filter(e => (e?.bookingId?.toLowerCase()?.indexOf((search?.toLowerCase())) >= 0))

      const getBookingCourtName = facilityListRes?.filter(e => (e?.name?.toLowerCase()?.indexOf((search?.toLowerCase())) >= 0))
      const filterBookingUsingCourtName = bookingListRes?.filter(x => (x?.facilities) === (getBookingCourtName[0]?._id))

      const getBookingTypeName = typeListRes?.filter(e => (e?.type_name?.toLowerCase()?.indexOf((search?.toLowerCase())) >= 0))
      const filterBookingUsingTypeName = bookingListRes?.filter(x => (x?.type_name) === (getBookingTypeName[0]?._id))

      const getMobile = playerListRes?.filter(e => (e?.mobile?.toLowerCase()?.indexOf((search?.toLowerCase())) >= 0))
      const filterBookingUsingMobile = bookingListRes?.filter(x => x?.player === getMobile[0]?._id)

      const getPlayerName = playerListRes?.filter(e => (e?.name?.toLowerCase()?.indexOf((search?.toLowerCase())) >= 0))
      const filterBookingUsingName = bookingListRes?.filter(x => x?.player === getPlayerName[0]?._id)

      if (getBookingId?.length > 0) {
        setfilteredBooking(getBookingId)
      }
      else if (filterBookingUsingCourtName?.length > 0) {
        setfilteredBooking(filterBookingUsingCourtName)
      }
      else if (filterBookingUsingTypeName?.length > 0) {
        setfilteredBooking(filterBookingUsingTypeName)
      }
      else if (filterBookingUsingMobile?.length > 0) {
        setfilteredBooking(filterBookingUsingMobile)
      }
      else if (filterBookingUsingName?.length > 0) {
        setfilteredBooking(filterBookingUsingName)
      }
      else {
        setfilteredBooking(null)
      }
    }
    else {
      setfilteredBooking(bookingListRes)
    }
  }, [bookingListRes, facilityListRes, playerListRes, search, typeListRes])


  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await apiClient.get(ApiConfig.facilities.getFacility);
        setfacilityListRes(response?.data?.facilityList)
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [])

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await apiClient.get(ApiConfig.facilities.getType);
        settypeListRes(response?.data?.typeList)
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [])


  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await apiClient.get(ApiConfig.facilities.getPlayer);
        setplayerListRes(response?.data?.playerList)
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [])

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await apiClient.get(ApiConfig.facilities.getBookingList);
        setbookingListRes(response?.data?.bookingList)
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [])

  const getTypeName = (values) => {
    const data2 = typeListRes?.filter(ee => ee?._id === values.toString())[0];
    if (data2?.type_name) {
      return data2?.type_name;
    }
  }

  const getFacilityName = (values) => {
    const data2 = facilityListRes?.filter(ee => ee?._id === values.toString())[0];
    if (data2?.name) {
      return data2?.name;
    }
  }

  const getPlayerName = (values) => {
    const data2 = playerListRes?.filter(ee => ee?._id === values)[0];
    if (data2?.name) {
      return data2?.name;
    }
  }

  const loadBookingInfo = async () => {

    try {
      setLoading(true);
      const response = await apiClient.get(ApiConfig.facilities.getBookingList);
      setbookingListRes(response?.data?.bookingList)
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const OnFinishRefund = async (values) => {
    try {
      setLoading(true);
      const response = await apiClient.patch(`${ApiConfig.website_payment.createRefund}/${values?.refId}`, values);
      if (response) {
        setshowModal(false)
        loadBookingInfo()
      }
      setLoading(false);
    } catch (error) {
      setshowModal(false)
      setLoading(false);
    }
  }

  const showBookingInfo = async (values) => {
    console.log(values,'100')
    if(values?.createBooking) {
      setcreateNewBooking(true)
      setbVisible(true)
    } else {
      setcVisible(true)
      if (values?._id) {
      const response = await apiClient.get(`${ApiConfig.payments.getPaymentInfo}/${values?._id}`);
      setselectedPaymentDetails(response?.data?.payment_details)
    }
    setselectedItem(values)
    }
  }

  const showRefundInfo = async (values) => {
    setselectedItem(values)
    setshowModal(true)
    setLoading(false)
  }

  const bookingColumns = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      // render: (text, record, key) => (key + 1).toString(),
      render: (text, record, index) => `${(page - 1) * 10 + index + 1}.`,
    },
    {
      title: "Booking ID",
      dataIndex: 'bookingId',
      render: (text, record) => {
        return (
          <>
            <span style={{ fontWeight: '400' }}>{text}</span>
            {record?.is_refund === true && (
              <>
                &nbsp;
                <Tag style={{ cursor: 'pointer' }} color={record?.is_refund ? '#00a1ff' : ''}>{record?.is_refund ? 'Refunded' : ''}</Tag>
              </>
            )}
          </>
        )
      },
      sorter: (a, b) => a.bookingId.localeCompare(b.bookingId),
    },
    {
      title: "Player Name",
      dataIndex: ['player'],
      key: "player",
      sorter: (a, b) => a.player.localeCompare(b.player),
      render: (text, record) => {
        return (
          <>
            {getPlayerName(record?.player)}
          </>
        )
      }
    },
    {
      title: "Booking Date",
      key: "booking_date",

      render: (text, record) => {
        return (
          <>
            {getDateFormat(record?.slot_booking_info?.booking_date)}
          </>
        )
      },
      sorter: (a, b) => a?.slot_booking_info?.booking_date?.localeCompare(b?.slot_booking_info?.booking_date),
    },
    {
      title: "Booking Time",
      dataIndex: ['slot_booking_info'],
      render: (text, record) => {
        return (
          <>
            {timeIntervals?.filter(a => a.value === record?.slot_booking_info?.from)[0]?.label || null}
            &nbsp;-&nbsp;
            {timeIntervals?.filter(a => a.value === record?.slot_booking_info?.to)[0]?.label || null}
          </>
        )
      }
    },
    {
      title: "Amount",
      key: '[payment_info,total_amount]',
      sorter: (a, b) => a.payment_info.total_amount - b.payment_info.total_amount,
      render: (record) => {
        // eslint-disable-next-line no-lone-blocks
        if (record)
          return (

            < span style={{ textAlign: 'right' }}>
              {`CAD $${Number(record?.payment_info?.total_amount / 100).toFixed(2)}`}
            </span >
          )
        return null
      },
    },
    {
      title: "Payment Status",
      dataIndex: 'status',
      render: (text, record) => {
        return (
          <>
            {record?.status === 'success' && (
              <>
                &nbsp;
                <Tag style={{ cursor: 'pointer' }} color={record?.status === 'success' ? 'green' : ''}>{record?.status === 'success' ? 'Success' : ''}</Tag>
              </>
            )}
          </>
        )
      },
      sorter: (a, b) => a.bookingId.localeCompare(b.bookingId),
    },
    {
      title: "Type",
      dataIndex: ['type_name'],
      key: "type_name",
      render: (text, record) => {
        return (
          <>{getTypeName(record?.type_name)}</>
        )
      }
    },
    {
      title: "Facility Name",
      dataIndex: ['facilities'],
      key: "facilities",
      render: (text, record) => {
        return (
          <>{getFacilityName(record?.facilities)}</>
        )
      }
    },
    {
      title: "Slot Type",
      key: "permit_duration",
      render: (text, record) => {
        return (
          <>
            {record?.is_prime_time && (
              <>
                Prime Time
              </>
            )}
            {record?.is_regular_time && (
              <>
                Regular Time
              </>
            )}
          </>
        )
      }
    },
    // {
    //   title: "Created Date",
    //   key: "created_at",
    //   sorter: (a, b) => a?.created_at.localeCompare(b?.created_at),
    //   render: (text, record) => {
    //     return (
    //       <>
    //         {getDateFormat2(record?.created_at)}
    //       </>
    //     )
    //   }
    // },
    {
      title: "Action",
      key: 'action',
      render: (record) => (
        <>
          <Button
            onClick={() => { showRefundInfo(record) }}
            type="link"
            className="h40"
            title="Refund"
            hidden={record?.is_refund === true}
            icon={<FiCreditCard />}
          />
          <Button
            onClick={() => { showBookingInfo(record) }}
            type="link"
            title="View Booking"
            className="h40"
            icon={<FiEye />}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <SectionTitle
        title="Booking"
        showBookingInfo={showBookingInfo}
        right={
          <>
            <div className="ant-item"><Input value={search} onChange={e => setSearch(e.target.value)} style={{ minWidth: "300px" }} placeholder="Search by booking ID or player or type or court or slot type.." /></div>
          </>
        }
      />
      <Table
        dataSource={filteredBooking?.filter(x => x.status === 'success')}
        columns={bookingColumns}
        loading={loading}
        // pagination={{
        //   pageSize,
        //   onShowSizeChange: (c, size) => {
        //     setPageSize(size);
        //   }
        // }}
        pagination={{
          onChange(current) {
            setPage(current)
          },
        }}
      />
      <Drawer
        open={cVisible}
        className="big-d2"
        title={`View Booking - ${getDateFormat(selectedItem?.slot_booking_info?.booking_date)}`}
        onClose={() => { setcVisible(false); }}
        destroyOnClose={true}
      >
        <div className='form-elements'>
          <ViewBooking
            cVisibleInfo={cVisible}
            currentItem={selectedItem}
            facilitiesArray={facilityListRes}
            typeArray={typeListRes}
            playerArray={playerListRes}
            timeIntervals={timeIntervals}
            currentPaymentInfo={selectedPaymentDetails}
            disabled={!!!createNewBooking}
          />
        </div>
      </Drawer>
      <Drawer
        open={bVisible}
        className="big-d2"
        title={`Create Booking - ${getDateFormat(selectedItem?.slot_booking_info?.booking_date)}`}
        onClose={() => { setbVisible(false);setcreateNewBooking(false) }}
        destroyOnClose={true}
      >
        <div className='form-elements'>
          <CreateBooking
            cVisibleInfo={bVisible}
            currentItem={selectedItem}
            facilitiesArray={facilityListRes}
            typeArray={typeListRes}
            playerArray={playerListRes}
            timeIntervals={timeIntervals}
            disabled={!!!createNewBooking}
          />
        </div>
      </Drawer>
      <RefundBooking
        description={
          <>
            {`${selectedItem?.bookingId}`}
          </>
        }
        open={showModal}
        onCancel={() => setshowModal(false)}
        destroyOnClose={true}
        info={selectedItem}
        currency="CAD"
        onSubmit={OnFinishRefund}
        loading={loading}
      />
    </>
  )
}

export default BookingComponent
