import React, { useEffect } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'
import store from 'store'
import Facilities from './pages/AllInApp/facilities'
import Booking from './pages/AllInApp/booking'
import Users from './pages/AllInApp/users'
import Customers from './pages/AllInApp/customers'
import Enquiry from './pages/AllInApp/enquiries'
import Website from './pages/AllInApp/website'
import Dashboard from './pages/AllInApp/dashboard'
import Coupon from './pages/AllInApp/coupon'
import Credit from './pages/AllInApp/credit'
import Logout from './pages/logout'
import LoginPage from './pages/auth/login'
import ForgotPassword from './pages/auth/forgot-password'
import ResetPassword from './pages/auth/reset-password'
import UserProfile from './pages/AllInApp/user-profile'




const Routes = () => {
  const history = useHistory();
  useEffect(() => {
    const token = store.get('user.accessToken');
    if (
      [
        '/',
        'login/',
        'register/',
        '/forgot-password',
        '/reset-password',
        '/verify-account',
      ].findIndex(ee => ee === window.location.pathname) === -1
    ) {
      if (!token && window.location.pathname !== '/') {
        history.replace('/');
      }
    }
    // eslint-disable-next-line
  }, [])
  // useEffect(() => {
  //   const token = store.get('user.accessToken');
  //   if (
  //     [
  //       '/',
  //       '/login',
  //       '/forgot-password',
  //       '/reset-password',
  //     ].findIndex(ee => ee === window.location.pathname) === -1
  //   ) {
  //     if (!token && window.location.pathname !== '/') {

  //       history.replace('/a');
  //     }
  //   }
  //   else {
  //     if (token) {
  //       history.replace('/profile');
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [])

  return (
    <Switch>
      <Route path="/dashboard">
        <Dashboard />
      </Route>
      <Route path="/coupon">
        <Coupon />
      </Route>
      <Route path="/credit">
        <Credit />
      </Route>
      <Route path="/facility">
        <Facilities />
      </Route>
      <Route path="/booking">
        <Booking />
      </Route>
      <Route path="/users">
        <Users />
      </Route>
      <Route path="/customers">
        <Customers />
      </Route>
      <Route path="/website">
        <Website />
      </Route>
      <Route path="/inquiries">
        <Enquiry />
      </Route>
      <Route path="/forgot-password">
        <ForgotPassword />
      </Route>
      <Route path="/reset-password">
        <ResetPassword />
      </Route>
      <Route path="/logout">
        <Logout />
      </Route>
      <Route path="/login">
        <LoginPage />
      </Route>
      <Route exact path="/">
        <LoginPage />
      </Route>
      <Route path="/profile">
        <UserProfile />
      </Route>
      <Route component={LoginPage} />
    </Switch>
  )
}
export default Routes
