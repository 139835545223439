import React, { useEffect } from 'react'
import { Button, Modal, Form, Select } from 'antd'

const Option = Select

const RemoveModal = ({
  open,
  title,
  description,
  onSubmit,
  onCancel,
  loading,
  currentStatus,
}) => {
  useEffect(() => {
  }, [title, loading])

  const [form] = Form.useForm();

  return (
    <Modal className="removal-modal" title={description} open={open} onCancel={onCancel} footer={null}>
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={onSubmit}
      >
        <Form.Item
          name="status"
          label="Status"
          rules={[{ required: true, message: "This field is required" }]}
        >
          <Select
            placeholder="Select From the List"
            size="large"
          >
            <Option key="Open" value="Open" hidden={currentStatus === 'Open'}>Open</Option>
            <Option key="Pending" value="Pending" hidden={currentStatus === 'Pending'}>Pending</Option>
            <Option key="Closed" value="Closed" hidden={currentStatus === 'Closed'}>Closed</Option>
          </Select>
        </Form.Item>
        <Form.Item className="text-right">
          <Button type="primary" htmlType="submit" size="large">
            Update
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default RemoveModal
