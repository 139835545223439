import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Select, InputNumber } from 'antd'
import { phoneNumberNational } from '../../../../utils/parser'
import moment from "moment";
import { getDateFormat2 } from '../../../../utils'
import apiClient from '../../../../services/axios'
import ApiConfig from '../../../../services/ApiConfig'

const Option = Select

const FacilityFormComponent = ({ cVisibleInfo, currentItem, facilitiesArray, typeArray, playerArray, timeIntervals, currentPaymentInfo }) => {

  const [form] = Form.useForm();
  const [defaultload, setdefaultload] = useState(true);
  const [permitDayTime, setPermitDayTime] = useState([])
  const [primeTimePermitDayTime, setprimeTimePermitDayTime] = useState([])
  const weekDaysPrimeTime = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  console.log("primeTimePermitDayTime", primeTimePermitDayTime)
  console.log("currentItem", currentItem)
  console.log("currentPaymentInfo", currentPaymentInfo)

  // eslint-disable-next-line no-undef
  useEffect(() => {
    (async () => {
      if (cVisibleInfo === true) {
        if (currentItem?.is_regular_time) {
          form.setFieldsValue({
            slot_type: 'regular',
          })
        }
        if (currentItem?.is_prime_time) {
          form.setFieldsValue({
            slot_type: 'primetime',
          })
        }
        if (currentItem?.player) {
          const response = playerArray?.filter(x => x._id === currentItem?.player) || null
          let formattedPhoneNumber = '-'
          const phoneNumber = phoneNumberNational(response[0]?.mobile)
          if (phoneNumber) formattedPhoneNumber = phoneNumber
          form.setFieldsValue({
            player_name: response[0]?.name,
            player_emailid: response[0]?.emailid,
            player_mobile: formattedPhoneNumber
          })
        }
        if (currentPaymentInfo) {
          form.setFieldsValue({
            payment_date: currentPaymentInfo?.payment_date,
            bill_amount: (currentItem?.payment_info?.amount + currentItem?.payment_info?.credit_amount_used + currentItem?.payment_info?.coupon_amount_used) / 100,
            credit_amount: currentItem?.payment_info?.credit_amount_used / 100,
            coupon_amount: currentItem?.payment_info?.coupon_amount_used / 100,
            amount: currentPaymentInfo?.amount / 100,
            payment_method: currentPaymentInfo?.payment_method,
            payment_reference: currentPaymentInfo?.payment_reference,
            created_at: getDateFormat2(currentItem?.created_at),
          })
        }

        form.setFieldsValue({
          type_name: currentItem?.type_name,
          name: currentItem?.court_name,
          permit_day: weekDays.indexOf(currentItem?.slot_booking_info?.day),
          rate: currentItem?.rate / 100,
        })
      }
      if (currentItem?.is_refund) {
        const response = await apiClient.patch(`${ApiConfig.website_payment.retrieveRefund}/${currentItem?._id}`);
        if (response) {
          form.setFieldsValue({
            refund_amount: (response?.data?.refund_amount / 100),
            refund_reference: response?.data?.refund_id,
          })
        }
      }
    })();
    // eslint-disable-line
  })

  const weekDayName = moment(currentItem?.slot_booking_info?.booking_date).format('dddd');

  useEffect(() => {
    if (cVisibleInfo === true && defaultload === true) {
      const temp = [];
      const temp2 = [];
      weekDays.forEach(w => {
        temp.push({
          day: w,
          from: timeIntervals[0].value,
          to: timeIntervals[timeIntervals.length - 1].value,
          show: w === 'Sunday'
        })
      })
      weekDaysPrimeTime.forEach(w => {
        temp2.push({
          day: w,
          from: timeIntervals[0].value,
          to: timeIntervals[timeIntervals.length - 1].value,
          rate: 0,
          show: w === 'Sunday'
        })
      })
      setdefaultload(false)
      setPermitDayTime([...temp])
      setprimeTimePermitDayTime([...temp2])
    }
  }, [cVisibleInfo, defaultload, timeIntervals, weekDays, weekDaysPrimeTime])

  return (
    <>
      <div className='form-elements'>
        <Form
          form={form}
          layout="vertical"
          requiredMark={false}
        >
          <div className='form-title'>Facility Information</div>
          <Form.Item
            label="Type Name"
            name="type_name"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Select
              showSearch
              disabled
              placeholder="Select a type"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
            >
              {typeArray?.filter(x => x.is_active === true)?.map((mval, index) => (
                <Option key={mval?._id} value={mval?._id}>{mval?.type_name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Court Name"
            name="name"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input placeholder="Enter a court name" disabled />
          </Form.Item>

          <div className='form-title'>Booking Info</div>
          <Form.Item
            name="slot_type"
            label="Slot Type"
            className='time_range'
          >
            <Select
              placeholder="Select From the List"
              size="large"
              disabled
            >
              <Option key="regular" value="regular">Regular Hours</Option>
              <Option key="primetime" value="primetime">Prime Time Hours</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="rate"
            label="Rate"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <InputNumber
              addonBefore="$"
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              style={{ width: '652px' }}
              placeholder="Enter the Rate"
              disabled={true}
            />
          </Form.Item>
          <Form.Item
            name="permit_day"
            label="Permit Day"
            className='time_range'
          >
            {weekDays.map((week, k) => {
              return (
                <>
                  <Button disabled key={k.toString()} className={`mr-3${week === weekDayName ? ' selected' : ''}`} shape="circle">{week?.slice(0, 2)}</Button>
                </>
              )
            })}
            {
              permitDayTime.filter(e => e.show).map((pp, k) => (
                <Form.Item
                  key={k.toString()}
                  name="time_range"
                  className="time_range_block"
                >
                  <Form.Item label={weekDayName?.slice(0, 2)}>
                    <Select disabled className='fromTime' showSearch value={currentItem?.slot_booking_info?.from} options={timeIntervals} placeholder="From" />
                  </Form.Item>
                  <Form.Item>
                    <Select disabled className='toTime' showSearch value={currentItem?.slot_booking_info?.to} options={timeIntervals} placeholder="To" />
                  </Form.Item>
                </Form.Item>
              ))
            }
          </Form.Item>
          <Form.Item
            label="Created Date"
            name="created_at"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input placeholder="Enter a payment date" disabled />
          </Form.Item>
          <div className='form-title'>Player Information</div>
          <Form.Item
            label="Player Name"
            name="player_name"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input placeholder="Enter a player name" disabled />
          </Form.Item>
          <Form.Item
            label="Player Email ID"
            name="player_emailid"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input placeholder="Enter a player email" disabled />
          </Form.Item>
          <Form.Item
            label="Player Phone Number"
            name="player_mobile"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input placeholder="Enter a player phone number" disabled />
          </Form.Item>
          <div className='form-title'>Payment Information</div>
          <Form.Item
            label="Payment Date"
            name="payment_date"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input placeholder="Enter a payment date" disabled />
          </Form.Item>
          <Form.Item
            label="Amount"
            name="bill_amount"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <InputNumber
              addonBefore="$"
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              style={{ width: '652px' }}
              placeholder="Enter the Amount"
              disabled={true}
            />
          </Form.Item>
          {currentItem?.payment_info?.credit_amount_used > 0 && (
            <>
              <Form.Item
                label="Credit Used"
                name="credit_amount"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <InputNumber
                  addonBefore="$"
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  style={{ width: '652px' }}
                  placeholder="Enter the Amount"
                  disabled={true}
                />
              </Form.Item>
            </>)}
          {currentItem?.payment_info?.coupon_amount_used > 0 && (
            <>
              <Form.Item
                label="Coupon Used"
                name="coupon_amount"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <InputNumber
                  addonBefore="$"
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  style={{ width: '652px' }}
                  placeholder="Enter the Amount"
                  disabled={true}
                />
              </Form.Item>
            </>)}
          <Form.Item
            label="Paid Amount (Bill Amount - Credit Used - Coupon Used)"
            name="amount"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <InputNumber
              addonBefore="$"
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              style={{ width: '652px' }}
              placeholder="Enter the Amount"
              disabled={true}
            />
          </Form.Item>
          <Form.Item
            label="Payment Method"
            name="payment_method"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input placeholder="Enter a payment method" disabled />
          </Form.Item>
          <Form.Item
            label="Payment Reference"
            name="payment_reference"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input placeholder="Enter a payment reference" disabled />
          </Form.Item>
          {currentItem?.is_refund && (
            <>
              <div className='form-title'>Refund Information</div>
              <Form.Item
                label="Refunded Amount"
                name="refund_amount"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <InputNumber
                  addonBefore="$"
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  style={{ width: '652px' }}
                  placeholder="Enter the Amount"
                  disabled={true}
                />
              </Form.Item>
              <Form.Item
                label="Refund Reference"
                name="refund_reference"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Input placeholder="Enter a refund reference" disabled />
              </Form.Item>

            </>)}
        </Form>
      </div>
    </>
  )
}

export default (FacilityFormComponent)