import React, { useEffect } from 'react'
import { Form, Input, Button, Select } from 'antd'
// import MaskedInput from 'antd-mask-input'
import { phoneNumberE164, phoneNumberNational } from '../../../../utils/parser'

const FormElements = ({ onFinishApi, loading, initialValues }) => {

  const [form] = Form.useForm();

  const onFinishData = (values) => {

    form.resetFields();
    const formdata = new FormData();
    formdata.append('first_name', values.first_name);
    formdata.append('last_name', values.last_name);
    formdata.append('email', values.email);
    formdata.append('roles', values.roles);
    formdata.append('mobile', phoneNumberE164(values.mobile));
    onFinishApi(values)
  }

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue({
        mobile: phoneNumberNational(initialValues?.mobile),
      })
    }
  }, [form, initialValues])




  return (
    <Form
      form={form}
      layout="vertical"
      requiredMark={false}
      onFinish={onFinishData}
      initialValues={{ ...initialValues }}
    >
      <Form.Item
        name="first_name"
        label="First Name"
        className="mt-3"
        rules={[{ required: true, message: "This field is required" }]}
      >
        <Input placeholder="Enter your first name" className="form-control form-control-light" />
      </Form.Item>
      <Form.Item
        name="last_name"
        label="Last Name"
        className="mt-3"
        rules={[{ required: true, message: "This field is required" }]}
      >
        <Input placeholder="Enter your last name" className="form-control form-control-light" />
      </Form.Item>
      <Form.Item
        name="roles"
        label="Roles"
        rules={[{ required: true, message: "Required" }]}
      >
        <Select size="large" placeholder="Select roles from list">
          <Select.Option value="Super Admin">Super Admin</Select.Option>
          <Select.Option value="Manager">Manager</Select.Option>
          <Select.Option value="Employee">Employee</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="email"
        label="Email ID"
        className="mt-3"
        rules={[{ required: true, message: "This field is required" }]}
      >
        <Input placeholder="Enter your email" className="form-control form-control-light" />
      </Form.Item>
      {/* <Form.Item
        name="mobile"
        label="Phone Number"
        rules={[
          () => ({
            validator(rule, value) {
              const phoneNumber = phoneNumberNational(value)
              if (!value || (phoneNumber && phoneNumber.length === 14)) {
                return Promise.resolve()
              }
              return Promise.reject(
                new Error(
                  "Invalid Phone Number",
                ),
              )
            },
          }),
        ]}
      >
        <MaskedInput
          mask="(111) 111-1111"
          placeholder="Phone Number"
          size="large"
        />
      </Form.Item> */}
      <Form.Item
        name="mobile"
        label="Phone"
        className="mt-3"
        rules={[{ required: true, message: "This field is required" }]}
      >
        <Input placeholder="Enter your phone number" className="form-control form-control-light" />
      </Form.Item>
      <Form.Item
        name="password"
        label="Password"
        className="mt-3"
        rules={[{ required: true, message: "This field is required" },
        ]}
      >
        <Input type="password" placeholder="Enter your password" className="form-control form-control-light" />
      </Form.Item>
      <Form.Item
        name="confirm_password"
        label="Confirm Password"
        className="mt-3"
        rules={[{ required: true, message: "This field is required" },
        ]}
      >
        <Input type="password" placeholder="Enter your password again" className="form-control form-control-light" />
      </Form.Item>
      <div className="form-footer">
        <Button loading={loading} type="primary" htmlType="submit">Create User</Button>
      </div>
    </Form>
  )
}

export default (FormElements)