/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react'
import { Drawer, Form, Radio, Table, Input, Select, Switch, Button, DatePicker, Tag, Tabs } from 'antd'
import getSymbolFromCurrency from 'currency-symbol-map'
import SectionTitle from '../../components/AllInApp/section-title'
import NumberInput from '../../common/NumberInput'
import RemoveModal from '../../common/RemoveModal'
import { FiPlus, FiEdit, FiTrash, FiRefreshCcw } from 'react-icons/fi'
import moment from 'moment'
import { notification } from 'antd'
import apiClient from '../../services/axios'
import ApiConfig from '../../services/ApiConfig'

const Option = Select

const Index = () => {
  const [selectedTab, setselectedTab] = useState('1');
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [couponList, setCouponList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [typeListRes, settypeListRes] = useState(null);
  const [BookingCoupon, setBookingCoupon] = useState(null);
  const [deletePopupVisible, setDeletePopupVisibe] = useState(false);
  const [statusChange, setStatusChange] = useState(false);
  const currency = "CAD"


  const callback = (key) => {
    setselectedTab(key)
  }

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        if (selectedTab === '1') {
          const response = await apiClient.get(ApiConfig.coupon.getActiveCouponList);
          setCouponList(response?.data?.couponList)
        }
        else if (selectedTab === '2') {
          const response = await apiClient.get(ApiConfig.coupon.getInactiveCouponList);
          setCouponList(response?.data?.couponList)
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [selectedTab])

  const loadCoupons = async () => {
    try {
      setLoading(true);
      if (selectedTab === '1') {
        const response = await apiClient.get(ApiConfig.coupon.getActiveCouponList);
        setCouponList(response?.data?.couponList)
      }
      else if (selectedTab === '2') {
        const response = await apiClient.get(ApiConfig.coupon.getInactiveCouponList);
        setCouponList(response?.data?.couponList)
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  function disabledDate(current) {
    return current < moment().startOf('day')
  }

  const getCheckCoupon = (values) => {
    const infoData = BookingCoupon?.filter(x => x?.coupon === values)
    let currentStatus = true
    if (infoData?.length === 0) {
      currentStatus = false
    }
    return currentStatus
  }

  const showCouponInfo = (couponRecord) => {
    setSelectedItem(couponRecord)
    setVisible(true)
  }

  const onDelete = async () => {
    await apiClient.delete(`${ApiConfig.coupon.deleteCoupon}/${selectedItem._id}`);
    loadCoupons();
    setDeletePopupVisibe(false);
  }

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await apiClient.get(ApiConfig.facilities.getType);
        settypeListRes(response?.data?.typeList)
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [])

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await apiClient.get(ApiConfig.coupon.getBookingCoupon);
        setBookingCoupon(response?.data?.bookingCoupon)
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [])

  const getFormattedDate = (dateTime) => {
    return moment(dateTime).format('MMMM-DD,YYYY HH:mm:ss A')
  }

  const onSubmitStatusChange = async () => {
    let currentStatus = 1
    if (selectedItem?.is_active === true) {
      currentStatus = 0
    }
    await apiClient.patch(`${ApiConfig.coupon.updateStatus}/${selectedItem._id}/${selectedItem.code}/${currentStatus}`);
    loadCoupons();
    setStatusChange(false);
  }

  const CouponColumns = [
    {
      title: "S.No",
      key: 's_no',
      render: (text, record, index) => `${index + 1}.`,
    },
    {
      title: "Coupon Code",
      dataIndex: 'code',
      key: 'code',
      render: (text, record) => (
        <>
          {record?.coupon_type === 'dollar' && (
            <>
              <Tag color="#2db7f5">
                <span className="font-size-14 font-weight-bold">{text}</span>
              </Tag>
            </>
          )}
          {record?.coupon_type === 'percent' && (
            <>
              <Tag color="#87d068">
                <span className="font-size-14 font-weight-bold">{text}</span>
              </Tag>
            </>
          )}
        </>
      ),
      sorter: (a, b) => a.code.localeCompare(b.code),
    },
    {
      title: "Auto Checkout",
      dataIndex: "auto_apply_checkout",
      key: "auto_apply_checkout",
      render: (text, record, idx) => {
        return (
          <div className="action_btns">
            <Tag style={{ cursor: 'pointer' }} color={text ? '#4BB543' : '#e40'}>{text ? 'Yes' : 'No'}</Tag>
          </div>
        )
      }
    },

    {
      title: "Description",
      dataIndex: 'description',
      key: 'description',
      sorter: (a, b) => a?.description.localeCompare(b?.description),
    },
    {
      title: "Value",
      key: 'value',
      render: (record) => {
        const { coupon_type, value } = record
        if (coupon_type === 'dollar')
          return (
            <strong>
              {`$${Number(value).toFixed(2)} CAD`}
            </strong>
          )
        if (coupon_type === 'percent')
          return (
            <strong>
              {`${Number(value).toFixed(2)} %`}
            </strong>
          )
        return null
      },
    },
    {
      title: "Usage",
      dataIndex: 'usage_count',
      key: 'usage_count',
      render: (text) => `${text} times`,
    },

    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      render: (text, record, idx) => {
        return (
          <div className="action_btns">
            <Tag style={{ cursor: 'pointer' }} color={text ? '#4BB543' : '#e40'}>{text ? 'Active' : 'Inactive'}</Tag>
          </div>
        )
      }
    },
    {
      title: "Start Date",
      dataIndex: 'starts_at',
      key: 'starts_at',
      render: (text) => getFormattedDate(text),
      sorter: (a, b) => a?.starts_at.localeCompare(b?.starts_at),
    },
    {
      title: "Expiry Date",
      dataIndex: 'expires_at',
      key: 'expires_at',
      render: (text) => getFormattedDate(text),
      sorter: (a, b) => a?.expires_at.localeCompare(b?.expires_at),
    },
    {
      title: "Action",
      key: 'action',
      render: (record) => (
        <>
          <Button onClick={() => showCouponInfo(record)} className='btn_edit' type="link" icon={<FiEdit />} />
          <Button onClick={() => { setSelectedItem(record); setStatusChange(true); }} type="link" icon={<FiRefreshCcw />} />
          <Button hidden={getCheckCoupon(record?._id)} onClick={() => { setSelectedItem(record); setDeletePopupVisibe(true); }} type="link" icon={<FiTrash />} />
        </>
      ),
    },
  ]

  const FormElements = ({ initialValues }) => {

    const [form] = Form.useForm();
    const [couponType, setCouponType] = useState(initialValues.coupon_type)
    const [BookingType, setBookingType] = useState(null)
    const [submiting, setSubmitting] = useState(false);
    const [currentCouponCode, setcurrentCouponCode] = useState(null);
    const [duplicateCoupon, setduplicateCoupon] = useState(null);
    const [currentStartDate, setcurrentStartDate] = useState(null);
    const [currentExpiryDate, setcurrentExpiryDate] = useState(null);
    const [uniqueCoupon, setuniqueCoupon] = useState(true);
    const [disabledField, setdisabledField] = useState(false);
    const [disabledSwitch, setdisabledSwitch] = useState(false);
    const [duplicate, setduplicate] = useState(null);
    const [messageLoaded, setmessageLoaded] = useState(false);
    // const [checked, setChecked] = useState(initialValues?.is_recurring);

    // const onChange = (e) => {
    //   console.log('checked = ', e.target.checked);
    //   setChecked(e.target.checked);
    // };

    // const label = `${checked ? 'Recurring' : 'No recurring'}`;

    const getDateFormat = (props, flag) => {
      const dFormat = "MMM DD, YYYY";
      if (typeof props === 'object') {
        const { date, format = dFormat, isTime } = props || {}
        if (!date) return;
        if (isTime) return moment(date).format(`${format} hh:mm a`)
        return moment(date).format(format)
      } else {
        if (flag) return props ? moment(props) : moment();
        return props ? moment(props).format(`${dFormat}`) : moment();
      }
    }


    console.log("messageLoaded", messageLoaded)


    useEffect(() => {

      (async () => {
        if (BookingType && initialValues?.booking_type && messageLoaded === false) {
          const defaultValues = initialValues?.booking_type
          const bTypeRes = BookingType?.filter(val => !defaultValues?.includes(val));
          if (bTypeRes?.length > 0) {
            const response = await apiClient.get(`${ApiConfig.coupon.checkDuplicates}/${bTypeRes[0]}`);
            console.log("api call", response)
            setmessageLoaded(true)
            if (response?.data?.duplicate === true) {
              setdisabledSwitch(true)
              const messageInfo = `You cannot select the Auto Checkout Feature ${duplicate} is already added with another coupon`
              // setduplicate(response?.data?.typeName)
              notification.warning({ message: messageInfo })
              form.setFieldsValue({
                auto_apply_checkout: false,
              })
            }
          }
          else {
            setmessageLoaded(false)
            setduplicate(null)
            setdisabledSwitch(false)
            form.setFieldsValue({
              auto_apply_checkout: initialValues?.auto_apply_checkout,
            })
          }
        }
      })();
      // eslint-disable-line
    })

    useEffect(() => {
      if (currentStartDate && currentExpiryDate) {
        const unixStartDate = new Date(currentStartDate).valueOf()
        const unixExpiryDate = new Date(currentExpiryDate).valueOf()
        if (unixStartDate > unixExpiryDate) {
          notification.warning({ message: "Start Date/Time must be less than Expiry Date/Time" })
          form.setFieldsValue({
            expires_at: '',
          })
        }
      }

    }, [currentStartDate, currentExpiryDate, form])

    useEffect(() => {
      if (duplicateCoupon) {
        if (duplicateCoupon?.data?.duplicate === false) {
          setuniqueCoupon(false)
          // notification.success({ message: "Coupon was unique" })
        }
        else if (duplicateCoupon?.data?.duplicate === true) {
          notification.error({ message: "Coupon already exists" })
          setuniqueCoupon(true)
        }
        form.setFieldsValue({
          value: currentCouponCode,
        })
      }
    }, [form, duplicateCoupon, currentCouponCode])

    useEffect(() => {
      if (initialValues?._id) {
        form.setFieldsValue({
          coupon_type: initialValues?.coupon_type,
          code: initialValues?.code,
          description: initialValues?.description,
          value: initialValues?.value,
          min_purchase_value: initialValues?.min_purchase_value,
          max_usage_limit: initialValues?.max_usage_limit,
          starts_at: getDateFormat(initialValues?.starts_at, true),
          expires_at: getDateFormat(initialValues?.expires_at, true),
          auto_apply_checkout: initialValues?.auto_apply_checkout,
          is_recurring: initialValues?.is_recurring,
          booking_type: initialValues?.booking_type?.map(e => e) || []
        })
        // setChecked(initialValues?.is_recurring)
        setuniqueCoupon(false)
      }
      else {
        form.setFieldsValue({
          coupon_type: initialValues?.coupon_type,
          max_usage_limit: initialValues?.max_usage_limit,
        })
        // setChecked(initialValues?.is_recurring)
      }

      if (initialValues?.code !== null) {
        setdisabledField(true)
      }
      else if (initialValues?.code === null) {
        setdisabledField(false)
      }

    }, [form, initialValues])

    const onValuesChange = (changedValues) => {
      if (changedValues) {
        setduplicateCoupon(null)
      }
      const { coupon_type, code, starts_at, expires_at, booking_type } = changedValues
      if (coupon_type) {
        setCouponType(coupon_type)
        form.setFieldsValue({
          value: null,
        })
      }
      if (booking_type) {
        setBookingType(booking_type)
      }
      if (code?.length >= 5) {
        setcurrentCouponCode(code)
        onCheckCouponExists(code)
      }
      if (starts_at) {
        setcurrentStartDate(starts_at)
      }
      if (expires_at) {
        setcurrentExpiryDate(expires_at)
      }
    }

    function usageLimitTimes() {
      const times = []
      for (let i = 1; i <= 5; i += 1) {
        times.push(i)
      }
      return times
    }

    const onCheckCouponExists = async (values) => {
      const response = await apiClient.patch(`${ApiConfig.coupon.checkExistingCoupon}/${values}`);
      if (response?.data) {
        setduplicateCoupon(response)
        setSubmitting(false);
      }
    }

    const onSubmit = async (values) => {
      const dFormat = "YYYY-MM-DD HH:mm";
      const formattedStartDate = moment(values?.starts_at)?.format(`${dFormat}`)
      const formattedExpiryDate = moment(values?.expires_at)?.format(`${dFormat}`)
      const payload = {
        "coupon_type": values?.coupon_type,
        "code": values?.code,
        "description": values?.description,
        "value": values?.value,
        "min_purchase_value": values?.min_purchase_value,
        "booking_type": values?.booking_type,
        "max_usage_limit": values?.max_usage_limit,
        "starts_at": formattedStartDate,
        "expires_at": formattedExpiryDate,
        "auto_apply_checkout": values?.auto_apply_checkout,
        "is_recurring": values?.is_recurring,
      }
      // setSubmitting(true);
      let response = null
      if (initialValues?._id) {
        response = await apiClient.patch(`${ApiConfig.coupon.updateCoupon}/${selectedItem?._id}`, payload);
      }
      else {
        response = await apiClient.post(ApiConfig.coupon.addCoupon, payload);
      }
      console.log("response", response.status)
      if (response) {
        // setSubmitting(false);
        loadCoupons();
        setVisible(false);
      }
      else {
        // setSubmitting(false);
      }
    }

    const onChangeEnrolled = (values) => {
      setmessageLoaded(false)
      if (values.indexOf('All') >= 0) {
        const tmp = [];
        typeListRes.forEach(e => {
          tmp.push(e._id);
        })
        form.setFieldsValue({
          ...form.getFieldsValue(),
          booking_type: tmp
        })
        console.log(tmp);
      }
    }

    return (
      <div className='form-elements'>
        <Form
          form={form}
          layout="vertical"
          requiredMark={false}
          onValuesChange={onValuesChange}
          onFinish={onSubmit}
        >
          <Form.Item
            label="Coupon Type"
            name="coupon_type"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Radio.Group buttonStyle="solid">
              <Radio.Button value="dollar">Flat Rate</Radio.Button>
              <Radio.Button value="percent">Percentage</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="code"
            label="Coupon Code"
            rules={[
              { required: true, message: "Required" },
              {
                pattern: new RegExp('^[A-Za-z0-9_-]*$'),
                message: "Special characters are not allowed",
              },
              { min: 5, message: "Code must be min 5 characters" },
              { max: 10, message: "Code must be up to 10 characters" },
            ]}
            normalize={(value) => (value || '').toUpperCase()}
          >
            <Input
              size="large"
              placeholder="Coupon code"
              maxLength={10}
              disabled={disabledField}
            />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true, message: "Required" }]}
          >
            <Input
              size="large"
              placeholder="Short description about the coupon"
              maxLength={64}

            />
          </Form.Item>
          <Form.Item
            name="value"
            label="Coupon Value"
            rules={[{ required: true, message: "Required" }]}
          >
            <NumberInput
              size="large"
              placeholder={couponType === 'dollar'
                ? 'Amount'
                : 'Percentage'}
              addonBefore={couponType === 'dollar' ? "$" : null}
              addonAfter={couponType === 'percent' ? '%' : null}
              max={couponType === 'percent' ? 100 : 9999.99}
              precision={couponType === 'percent' ? 2 : 2}
            />
          </Form.Item>
          <Form.Item
            name="min_purchase_value"
            label="Mininum Booking Amount"
            rules={[
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  const couponValue = getFieldValue('value') || 0
                  if (couponType === 'dollar' && parseFloat(value) <= parseFloat(couponValue)) {
                    return Promise.reject(
                      new Error("Minimum Booking Amount should be higher than the Coupon Value"),
                    )
                  }
                  return Promise.resolve()
                },
              }),
            ]}
          >
            <NumberInput
              size="large"
              placeholder="0.00"
              addonBefore={getSymbolFromCurrency(currency)}
            />
          </Form.Item>
          <Form.Item
            name="booking_type"
            className='with-helper'
            label={<>Booking Type <div className='helper-text'>Coupon will be valid only for the selected booking types</div></>}
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Select
              allowClear
              onChange={onChangeEnrolled}
              mode="multiple"
              placeholder="Select Booking Type"
              options={typeListRes?.filter(x => x.is_active === true)?.map(ee => ({ label: ee?.type_name, value: ee?._id }))}
            />
          </Form.Item>
          <Form.Item
            name="max_usage_limit"
            label="Maximum Usage Limit per Customer"
            rules={[{ required: true, message: "Required" }]}
          >
            <Select size="large">
              <Select.Option value={0}>Unlimited</Select.Option>
              {usageLimitTimes().map((o) => (
                <Select.Option key={o} value={o}>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="starts_at"
            label="Start Date"
            rules={[{ required: true, message: "Required" }]}
          >
            <DatePicker
              style={{ width: '100%' }}
              showTime
              size="large"
              format="YYYY-MM-DD HH:mm"
              disabledDate={disabledDate}
              allowClear={false}
            />
          </Form.Item>
          <Form.Item
            name="expires_at"
            label="Expiry Date"
            rules={[{ required: true, message: "Required" }]}
          >
            <DatePicker
              style={{ width: '100%' }}
              showTime
              size="large"
              format="YYYY-MM-DD HH:mm"
              disabledDate={disabledDate}
              allowClear={false}
            />
          </Form.Item>

          {/* <Form.Item
            name="is_recurring"
            label="Apply coupon recurring?"
            valuePropName="checked"
          >
            <Checkbox checked={checked} onChange={onChange}>
              {label}
            </Checkbox>
          </Form.Item> */}
          <Form.Item
            name="is_recurring"
            label="Apply coupon recurring?"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          {/* {duplicate && (
            <>
              <span style={{ color: 'red' }}>You cannot select the Auto Checkout Feature, {duplicate} is already added with another coupon </span>
            </>
          )} */}

          <Form.Item
            name="auto_apply_checkout"
            label="Apply coupon automatically at checkout?"
            valuePropName="checked"
          >
            <Switch disabled={disabledSwitch} />
          </Form.Item>
          <div className="form-footer">
            <Button hidden={uniqueCoupon} loading={submiting} type="primary" htmlType="submit">{selectedItem?._id ? 'Update' : 'Add'} Coupon</Button>
          </div>
        </Form>
      </div>
    )
  }


  const onAdd = () => {
    setSelectedItem({
      coupon_type: "dollar",
      max_usage_limit: 0,
      code: null,
      description: null,
      value: null,
      min_purchase_value: null,
      starts_at: null,
      expires_at: null,
      auto_apply_checkout: null,
      is_recurring: null,
    });
    setVisible(true);
  }

  const statusList = [
    {
      "key": "1",
      "value": "Active"
    },
    {
      "key": "2",
      "value": "Inactive"
    }
  ]

  const onChangeStatus = async (e) => {
    setselectedTab(e)
    callback(e)
  }


  const FilterBlock = () => (
    <div className='top-filter-block'>
      <Select
        placeholder="Select from the list"
        size="large"
        onChange={onChangeStatus}
        defaultValue={selectedTab}
      >
        {statusList?.map((mval, index) => (
          <Option key={mval.key} value={mval.key} selected>{mval.value}</Option>
        ))}
      </Select>
    </div>
  )

  return (
    <>
      <RemoveModal description={`Are you sure you want to delete this coupon ${selectedItem?.code}?`} open={deletePopupVisible} onSubmit={onDelete} onCancel={() => setDeletePopupVisibe(false)} />
      <RemoveModal
        description={`Are you sure you want to change status for ${selectedItem?.code}?`}
        open={statusChange}
        onSubmit={onSubmitStatusChange}
        onCancel={() => setStatusChange(false)}
      />
      <SectionTitle
        title="Coupon"
        right={
          <>
            <FilterBlock />
          </>
        } />
      <Tabs
        defaultActiveKey={selectedTab}
        activeKey={selectedTab}
        onChange={callback}
        tabBarExtraContent={
          <>
            <button hidden={selectedTab === '2'} onClick={onAdd} className="btn btn-default btn-rounded btn-icon bg-blue-500 hover:bg-blue-600 text-white space-x-1">
              <FiPlus className="stroke-current text-white" size={16} />
              <span>Add Coupon</span>
            </button>
          </>
        }
      >
        <Tabs.TabPane key="1" tab="Active" className={selectedTab === '1' ? 'ant-tabs-tab-active' : ''}>
          <Table
            loading={loading}
            pagination={false}
            dataSource={couponList}
            columns={CouponColumns}
          />
        </Tabs.TabPane>

        <Tabs.TabPane key="2" tab="Inactive" className={selectedTab === '2' ? 'ant-tabs-tab-active' : ''}>
          <Table
            loading={loading}
            pagination={false}
            dataSource={couponList}
            columns={CouponColumns}
          />
        </Tabs.TabPane>
      </Tabs>

      <Drawer
        title={`${selectedItem?._id ? 'Update' : 'Add'} Coupon`}
        onClose={() => setVisible(false)}
        open={visible}
        destroyOnClose={true}
      >
        {visible &&
          <FormElements
            initialValues={{
              ...selectedItem
            }}
          />}
      </Drawer>

    </>
  )
}
export default Index
