import React from 'react'
import moment from "moment";
import { sortableHandle } from 'react-sortable-hoc'
import { MenuOutlined } from '@ant-design/icons'
import styles from './drag-handle/style.module.scss'

export const DragHandle = sortableHandle(() => {
  return (
    <div className={styles.dragHandle}>
      <MenuOutlined />
    </div>
  )
})

export const getFullName = (props) => {
  if (!props) return;
  const { name = '' } = props
  if (name) {
    return name + ' ';
  }
  return name || ""
}

export const getDateFormat = (props, flag) => {
  const dFormat = 'ddd, DD MMM, YYYY';
  if (typeof props === 'object') {
    const { date, format = dFormat, isTime } = props || {}
    if (!date) return;
    if (isTime) return moment(date).format(`${format} hh:mm a`)
    return moment(date).format(format)
  } else {
    if (flag) return props ? moment(props) : moment();
    return props ? moment(props).format(`${dFormat}`) : moment();
  }
}

export const getDateFormat2 = (props, flag) => {
  const dFormat = "D MMM, YYYY. HH:mm:ss A";
  if (typeof props === 'object') {
    const { date, format = dFormat, isTime } = props || {}
    if (!date) return;
    if (isTime) return moment(date).format(`${format} hh:mm a`)
    return moment(date).format(format)
  } else {
    if (flag) return props ? moment(props) : moment();
    return props ? moment(props).format(`${dFormat}`) : moment();
  }
}

export function getTimingsByIntervals(interval = 5, from = 0, to = 24) {
  const result = [];
  const start = new Date();
  start.setHours(from, 0, 0); // 00 AM
  const end = new Date();
  end.setHours(to, 0, 0); // 12 AM

  while (start <= end) {
    result.push(start.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit' }));
    start.setMinutes(start.getMinutes() + interval);
  }
  return result.filter(e => !!e).filter((x, i, a) => a.indexOf(x) === i);
}

export const hoursToMinutes = (hour = '') => {
  if (!hour) return null
  let hours = parseInt(hour.split(':')[0], 10)
  const mins = parseInt(hour.split(':')[1] || '00', 10) / 60
  const meridiem = hour.split(' ')[1]
  if (hour === '12:00 AM') {
    return 0
  }
  if (meridiem === 'PM' && hours < 12) {
    hours += 12
  }
  if (meridiem === 'AM' && hours >= 12) {
    hours -= 12
  }
  return Math.round((hours + mins) * 60)
}

export const minutesToHours = mins => {
  let hours = Math.trunc(mins / 60)
  if (hours > 12) {
    hours -= 12
  } else if (hours === 0) {
    hours = 12
  }
  const minutes = mins % 60
  return hours
    .toString()
    .concat(':')
    .concat(minutes.toString().length === 1 ? '0'.concat(minutes.toString()) : minutes.toString())
    .concat(mins >= 0 ? ' PM' : ' AM')
}

export const timeIntervals = [
  {
    "label": "12:00 AM",
    "value": 0
  },
  {
    "label": "01:00 AM",
    "value": 60
  },
  {
    "label": "02:00 AM",
    "value": 120
  },
  {
    "label": "03:00 AM",
    "value": 180
  },
  {
    "label": "04:00 AM",
    "value": 240
  },
  {
    "label": "05:00 AM",
    "value": 300
  },
  {
    "label": "06:00 AM",
    "value": 360
  },
  {
    "label": "07:00 AM",
    "value": 420
  },
  {
    "label": "08:00 AM",
    "value": 480
  },
  {
    "label": "09:00 AM",
    "value": 540
  },
  {
    "label": "10:00 AM",
    "value": 600
  },
  {
    "label": "11:00 AM",
    "value": 660
  },
  {
    "label": "12:00 PM",
    "value": 720
  },
  {
    "label": "01:00 PM",
    "value": 780
  },
  {
    "label": "02:00 PM",
    "value": 840
  },
  {
    "label": "03:00 PM",
    "value": 900
  },
  {
    "label": "04:00 PM",
    "value": 960
  },
  {
    "label": "05:00 PM",
    "value": 1020
  },
  {
    "label": "06:00 PM",
    "value": 1080
  },
  {
    "label": "07:00 PM",
    "value": 1140
  },
  {
    "label": "08:00 PM",
    "value": 1200
  },
  {
    "label": "09:00 PM",
    "value": 1260
  },
  {
    "label": "10:00 PM",
    "value": 1320
  },
  {
    "label": "11:00 PM",
    "value": 1380
  }
]