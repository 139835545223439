import React, { useEffect } from 'react'
import { Button, Form, Input } from 'antd'

const SecuritySettings = ({ onSubmit, dataClear }) => {

  const [form] = Form.useForm();


  useEffect(() => {
    if (dataClear === true) {

    }
    form.setFieldsValue({
      "old_password": '',
      "new_password": '',
      "confirm_new_password": '',
    })
  }, [form, dataClear])

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        requiredMark={false}
      >
        <div className="flex-list">
          <Form.Item
            name="old_password"
            label="Current Password"
            rules={[
              { required: true, message: "This field is required" },
              { min: 8, message: "Minimum length of 8 characters" },
              { max: 25, message: "Maximum length of 32 characters" },
            ]}
          >
            <Input
              size="large"
              placeholder="Please enter old password"
              maxLength={25}
              type="password"
            />
          </Form.Item>
          <Form.Item
            name="new_password"
            label="New Password"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input type="password" maxLength={25} placeholder="Please enter the new password" />
          </Form.Item>
          <Form.Item
            name="confirm_new_password"
            label="Confirm New Password"
            rules={[
              { required: true, message: "This field is required" },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('new_password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error('Password Mismatch'))
                },
              }),
            ]}
          >
            <Input
              type="password"
              size="large"
              placeholder="Please enter new password again"
              maxLength={25}
            />
          </Form.Item>
        </div>
        <div className="form-footer" style={{ textAlign: "left" }}>
          <Button type="primary" className="h40" htmlType="submit">Change</Button>
        </div>
      </Form>
    </>
  )
}

export default (SecuritySettings)