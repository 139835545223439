import React from 'react'
import $ from 'jquery';
import moment from 'moment';
import {
  FiBook,
  FiClock,
  FiCompass,
  FiCreditCard,
  FiDollarSign,
  FiGlobe,
  FiMessageSquare,
  FiUser,
  FiUsers,
} from 'react-icons/fi'

export function getMenuItems() {
  window.$ = $;
  window.moment = moment;
  return [
    {
      title: 'All-In',
      items: [
        {
          url: '/dashboard',
          icon: <FiCompass size={20} />,
          title: 'Dashboard',
          items: [],
          roles: ["Super Admin"]
        },

        {
          url: '/booking',
          icon: <FiClock size={20} />,
          title: 'Booking',
          items: [],
          roles: ["Super Admin"]
        },
        {
          url: '/coupon',
          icon: <FiDollarSign size={20} />,
          title: 'Coupon',
          items: [],
          roles: ["Super Admin"]
        },
        {
          url: '/credit',
          icon: <FiCreditCard size={20} />,
          title: 'Credit',
          items: [],
          roles: ["Super Admin"]
        },
        {
          url: '/inquiries',
          icon: <FiMessageSquare size={20} />,
          title: 'Inquiry',
          items: [],
          roles: ["Super Admin"]
        },
        {
          url: '/facility',
          icon: <FiBook size={20} />,
          title: 'Facility',
          items: [],
          roles: ["Super Admin"]
        },
        {
          url: '/users',
          icon: <FiUser size={20} />,
          title: 'Users',
          items: [],
          roles: ["Super Admin"]
        },
        {
          url: '/customers',
          icon: <FiUsers size={20} />,
          title: 'Customers',
          items: [],
          roles: ["Super Admin"]
        },
        {
          url: '/website',
          icon: <FiGlobe size={20} />,
          title: 'Website',
          items: [],
          roles: ["Super Admin"]
        },
        
      ]
    },
  ]
}

export default getMenuItems;