import React, { useState, useEffect, useRef } from 'react'
import AccountLinks from './account-links'
import store from 'store'
// import { getNameFirstLetters } from "../../utils";

const Dropdown = () => {
  const [hidden, setHidden] = useState(true)

  const buttonRef = useRef(null)
  const dropdownRef = useRef(null)
  const user = store.get('user.data');

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        hidden ||
        buttonRef.current.contains(event.target) ||
        dropdownRef.current.contains(event.target)
      ) {
        return false
      }
      setHidden(!hidden)
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [hidden, dropdownRef, buttonRef])

  const handleDropdownClick = () => {
    setHidden(!hidden)
  }


  const propsdata = { "first_name": user?.first_name, "last_name": user?.last_name }


  const getNameFirstLetters = (props) => {
    if (!props) return;
    const { first_name = '', last_name = '' } = props
    if (first_name && last_name) {
      return first_name[0].toUpperCase() + last_name[0].toUpperCase()
    } else if (first_name) {
      return first_name[0].toUpperCase() + first_name[1]?.toUpperCase()
    }
    return ''
  }

  return (
    <div className="relative" style={{ marginRight: '15px' }}>
      <button
        ref={buttonRef}
        onClick={handleDropdownClick}
        className="flex h-16 w-8 rounded-full ml-2 relative">
        <span className="absolute top-0 left-0 pt-4">
          {user?.profile_pic !== null && (
            <>
              <img
                className="h-8 w-8 rounded-full shadow"
                src={user?.profile_pic || 'avatar.jpg'}
                alt="avatar"
              />
            </>
          )}
          {user?.profile_pic === null && (
            <>
              {/* <img
                className="h-8 w-8 rounded-full shadow"
                src={user?.profile_pic}
                alt="avatar"
                hidden={user?.profile_pic}
              /> */}
              {getNameFirstLetters(propsdata)}
            </>
          )}

        </span>
      </button>
      <div ref={dropdownRef}
        className={`dropdown absolute top-0 right-0 mt-16 ${hidden ? '' : 'open'}`}>
        <div className="dropdown-content w-48 bottom-end">
          <AccountLinks />
        </div>
      </div>
    </div>
  )
}

export default Dropdown
