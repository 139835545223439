import React, { useEffect } from 'react'
import { Form, Input } from 'antd'
// import { InboxOutlined } from '@ant-design/icons'

const { TextArea } = Input;

const HomepageBanner = ({ cVisibleInfo, currentItem }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (currentItem) {
      form.setFieldsValue({
        message: currentItem?.message,
      })
    }
  }, [form, currentItem])



  return (
    <>

      <Form
        layout="vertical"
        form={form}
        requiredMark={false}
      >
        <div className="flex flex-col lg:flex-row w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4">
          <div className="w-full lg:w-4/4">

            <Form.Item
              name="message"
              label="Message"
              className="mt-3 textarea-input"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <TextArea readOnly style={{ resize: 'none', height: '100%' }} rows={8} placeholder="Enter your content" maxLength={800} />
            </Form.Item>
          </div>
        </div>
      </Form>
    </>
  )
}
export default HomepageBanner
