import React, { useEffect } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { FiMenu } from 'react-icons/fi'
import $ from 'jquery'
import store from 'store'
import Dropdown5 from './dropdown-5'
import '../../css/components/navbar.css'
import DaySwitch2 from '../day-switch-2'

const Navbar = () => {
  const { config } = useSelector(
    state => ({
      config: state.config
    }),
    shallowEqual
  )
  let { collapsed } = { ...config }
  const dispatch = useDispatch();
  const user = store.get('user.data');

  useEffect(() => {
    if (user?.first_name) {
      dispatch({
        type: 'SET_CONFIG_KEY',
        key: 'first_name',
        value: user?.first_name
      })
    }
    if (window.innerWidth < 780) {
      $(window).on('click', (e) => {
        if ($(e.target).closest('.m-menu-btn').length > 0) {
          return;
        } else {
          dispatch({
            type: 'SET_CONFIG_KEY',
            key: 'collapsed',
            value: false
          })
        }
      })
    }
    // eslint-disable-next-line
  }, [])

  const onChangeTheme = (e) => {
    console.log(e.target.checked);
    let mode = 'light';
    if (e.target.checked) {
      mode = 'dark'
    }
    window.localStorage.setItem("mode", mode);
    $('[data-layout="layout-1"]').attr('data-background', mode);
    $('[data-layout="layout-1"]').attr('data-navbar', mode);
    $('[data-layout="layout-1"]').attr('data-left-sidebar', mode);
    $('[data-layout="layout-1"]').attr('data-background', mode);
    $('[data-layout="layout-1"]').removeClass(mode === 'light' ? 'dark-mode' : 'light-mode').addClass(`${mode}-mode`);
    $('body').removeClass(mode === 'light' ? 'dark-body' : 'light-body').addClass(`${mode}-body`);
  }

  return (
    <div className="navbar navbar-1 border-b">
      <div className="navbar-inner w-full flex items-center justify-start">
        <button
          onClick={() =>
            dispatch({
              type: 'SET_CONFIG_KEY',
              key: 'collapsed',
              value: !collapsed
            })
          }
          className="mx-4 m-menu-btn">
          <FiMenu size={20} />
        </button>
        <span className="user_name">Welcome, {user?.first_name} {user?.last_name}</span>
        <span className="ml-auto"></span>
        <Dropdown5 />
        <DaySwitch2 onChange={(e) => onChangeTheme(e)} />
      </div>
    </div>
  )
}

export default Navbar
