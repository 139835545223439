import React, { useState, useEffect } from 'react'
import { Table, Tabs, Tag, Drawer, Button } from 'antd'
import { FiPlus, FiEdit, FiTrash, FiRefreshCcw } from 'react-icons/fi'
import AddFacilityForm from '../../components/AllInApp/Facilities/AddFacilityForm'
import UpdateFacilityForm from '../../components/AllInApp/Facilities/UpdateFacilityForm'
import TypeForm from '../../components/AllInApp/Facilities/TypeForm'
import RemoveModal from '../../common/RemoveModal'
import SectionTitle from '../../components/AllInApp/section-title'
import { timeIntervals } from '../../utils/'
import apiClient from '../../services/axios'
import ApiConfig from '../../services/ApiConfig'


const Facilities = () => {
  const weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const [selectedTab, setselectedTab] = useState('1');
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [FVisible, setFVisible] = useState(false);
  const [Tvisible, setTvisible] = useState(false);
  const [cVisible, setcVisible] = useState(false);
  const [typeListRes, settypeListRes] = useState(null);
  const [facilityListRes, setfacilityListRes] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedStatus, setselectedStatus] = useState(null);
  const [permitDayTime, setPermitDayTime] = useState([])
  const [PermitDayTime2, setPermitDayTime2] = useState([])
  const [primeDayPermitDayTime, setPrimeDayPermitDayTime] = useState([])
  // const [defaultload, setdefaultload] = useState(true);
  const [TypeStatusPopupVisible, setTypeStatusPopupVisible] = useState(false);
  const [deletePopupVisible, setDeletePopupVisibe] = useState(false);

  const callback = (key) => {
    setselectedTab(key)
  }

  const showFacilitiesInfo = (values) => {

    if (values.regular.permit_day_time) {
      const temp = [];
      values.regular.permit_day_time.forEach(pp => {
        temp.push({
          day: weekDays[pp.day],
          from: pp?.timings[0]?.from,
          to: pp?.timings[0]?.to,
          show: true
        })
      })
      setPermitDayTime(temp)
    }

    if (values.prime_time.permit_day_time) {
      const temp = [];
      const arrayData = values?.prime_time?.permit_day_time
      arrayData.forEach(pp => {
        temp.push({
          day: weekDays[pp.day],
          from: pp?.timings[0]?.from,
          to: pp?.timings[0]?.to,
          rate: pp?.rate,
          show: true
        })
      })
      setPrimeDayPermitDayTime(temp)
    }

    const temp2 = [];
    weekDays.forEach(w => {
      temp2.push({
        day: w,
        from: timeIntervals[0].value,
        to: timeIntervals[timeIntervals.length - 1].value,
        show: true
      })
    })
    setPermitDayTime2([...temp2])

    setSelectedItem(values)
    setcVisible(true)
  }

  const OntoggleTypeStatusChange = (e, record, val) => {
    setSelectedItem(record);
    setselectedStatus(val)
    setTypeStatusPopupVisible(true);
  }

  const onChangeStatus = async () => {
    await apiClient.patch(`${ApiConfig.facilities.updateTypeStatus}/${selectedItem}`);
    setTypeStatusPopupVisible(false);
    loadType()
    setselectedStatus(null)
  }

  const onFinishType = async (values) => {
    try {
      setLoading(true);
      await apiClient.post(ApiConfig.facilities.addType, values);
      setLoading(false);
      setTvisible(false)
      loadType()
    } catch (error) {
      setLoading(false);
    }
  }

  const onFinishFacility = async (values) => {
    try {
      setLoading(true);
      await apiClient.post(ApiConfig.facilities.addFacility, values);
      setLoading(false);
      setFVisible(false)
      loadFacility()
    } catch (error) {
      setLoading(false);
    }
  }

  const onFinishUpdateFacility = async (values) => {
    try {
      setLoading(true);
      await apiClient.post(ApiConfig.facilities.updateFacility, values);
      setLoading(false);
      setcVisible(false)
      loadFacility()
    } catch (error) {
      setLoading(false);
    }
  }

  const loadType = async () => {
    try {
      setLoading(true);
      const response = await apiClient.get(ApiConfig.facilities.getType);
      settypeListRes(response?.data?.typeList)
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const loadFacility = async () => {
    try {
      setLoading(true);
      const response = await apiClient.get(ApiConfig.facilities.getFacility);
      setfacilityListRes(response?.data?.facilityList)
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }


  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await apiClient.get(ApiConfig.facilities.getFacility);
        setfacilityListRes(response?.data?.facilityList)
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [])



  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await apiClient.get(ApiConfig.facilities.getType);
        settypeListRes(response?.data?.typeList)
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [])


  const getTimeValue = (value) => {
    const data = timeIntervals?.filter(a => a.value === value)[0]?.label
    return data
  }


  const getTypeName = (values) => {
    const data2 = typeListRes?.filter(ee => ee?._id === values)[0];
    if (data2?.type_name) {
      return data2?.type_name;
    }
  }

  const onDelete = async () => {
    await apiClient.delete(`${ApiConfig.facilities.deleteFacilities}/${selectedItem._id}`);
    loadFacility();
    setDeletePopupVisibe(false);
  }

  // const iftrue = '#f4551e'
  // const iffalse = '#108ee9'

  // const istrueText = 'Prime Time'
  // const iffalseText = 'Regular Time'

  const TypeColumns = [
    {
      title: "Type Name",
      dataIndex: ['type_name'],
      key: "type_name",
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      render: (text, record, idx) => {
        return (
          <div className="action_btns">
            <Tag style={{ cursor: 'pointer' }} color={text ? '#4BB543' : '#e40'} onClick={(e, event) => OntoggleTypeStatusChange(e, record?._id, record?.is_active)}>{text ? 'Active' : 'Inactive'}</Tag>
          </div>
        )
      }
    },
  ];


  const FacilitiesColumns = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      render: (text, record, key) => (key + 1).toString()
    },
    {
      title: "Type",
      dataIndex: ['type_name'],
      key: "type_name",
      render: (text, record) => (
        <>
          {getTypeName(record?.type_name)}
          &nbsp;
          {/* {record?.is_prime_time && (
            <>
              <Tag
                color={record?.is_prime_time ? iftrue : iffalse}
              >
                {record?.is_prime_time ? istrueText : iffalseText}
              </Tag>
            </>
          )} */}
        </>
      )
    },
    {
      title: "Facility Name",
      dataIndex: ['name'],
      key: "name",
    },
    // {
    //   title: "Permit Duration",
    //   dataIndex: ['permit_duration'],
    //   key: "permit_duration",
    //   render: (text, record) => {
    //     return (
    //       <>

    //         {text !== 'Unlimited' && (
    //           <>
    //             {text === '1' ? `${text} Month` : `${text} Months`}
    //           </>
    //         )}

    //         {
    //           text === 'Unlimited' && (
    //             <>
    //               {`Unlimited`}
    //             </>
    //           )
    //         }

    //       </>
    //     )
    //   }
    // },
    {
      title: "Regular Permit Day",
      dataIndex: "permit_day",
      key: "permit_day",
      width: "160px",
      render: (text, record) => {
        return (
          <div className='permit_day'>
            {record?.regular?.permit_day_time?.map(e => weekDays[e.day])?.map(tt => <><span>{tt}</span><br /></>)}
          </div>
        )
      }
    },
    {
      title: "Regular Time",
      dataIndex: "permit_time",
      key: "permit_time",
      width: "200px",
      render: (text, record) => {
        const timings = record?.regular?.permit_day_time?.map(e => e.timings);
        return (
          <div className='permit_day'>
            {timings.map(tm => <><span>{
              tm.map(tt => `${getTimeValue(tt.from)} - ${getTimeValue(tt.to)}`)
            }</span><br /></>)}
          </div>
        )
      }
    },
    {
      title: "Prime Permit Day",
      dataIndex: "permit_day_time",
      key: "permit_day_time",
      width: "160px",
      render: (text, record) => {
        return (
          <div className='permit_day'>
            {record?.prime_time?.permit_day_time?.map(e => weekDays[e.day])?.map(tt => <><span>{tt}</span><br /></>)}
          </div>
        )
      }
    },
    {
      title: "Prime Time",
      key: "prime_time",
      width: "200px",
      render: (text, record) => {
        const timings = record?.prime_time?.permit_day_time?.map(e => e.timings);
        return (
          <div className='permit_day'>
            {timings.map(tm => <><span>{
              tm.map(tt => `${getTimeValue(tt.from)} - ${getTimeValue(tt.to)}`)
            }</span><br /></>)}
          </div>
        )
      }
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      render: (text, record, idx) => {
        return (
          <div className="action_btns">
            <Tag style={{ cursor: 'pointer' }} color={text ? '#4BB543' : '#e40'}>{text ? 'Active' : 'Inactive'}</Tag>
          </div>
        )
      }
    },
    {
      title: "Action",
      key: 'action',
      render: (record) => (
        <>
          <Button hidden={!record.is_active} onClick={() => showFacilitiesInfo(record)} className='btn_edit' type="link" icon={<FiEdit />} />
          <Button onClick={() => { setSelectedItem(record); setDeletePopupVisibe(true); }} type="link" icon={record.is_active ? <FiTrash /> : <FiRefreshCcw />} />
        </>
      ),
    },
  ];

  return (
    <>
      <RemoveModal
        description={selectedStatus === true ? 'Are you sure you want to change the status to InActive?' : 'Are you sure you want to change the status to Active?'}
        open={TypeStatusPopupVisible}
        onSubmit={onChangeStatus}
        onCancel={() => setTypeStatusPopupVisible(false)}
      />
      <RemoveModal description={selectedItem?.is_active ? 'Are you sure you want to mark this court inactive?' : 'Are you sure you want to active the court?'} open={deletePopupVisible} onSubmit={onDelete} onCancel={() => setDeletePopupVisibe(false)} />


      <SectionTitle
        title="Facilities"
        right={
          <>
            <button hidden={selectedTab !== '1'} onClick={() => { setFVisible(true); }} className="btn btn-default btn-rounded btn-icon bg-blue-500 hover:bg-blue-600 text-white space-x-1">
              <FiPlus className="stroke-current text-white" size={16} />
              <span>Add Facility</span>
            </button>
            <button hidden={selectedTab !== '2'} onClick={() => { setTvisible(true) }} className="btn btn-default btn-rounded btn-icon bg-blue-500 hover:bg-blue-600 text-white space-x-1">
              <FiPlus className="stroke-current text-white" size={16} />
              <span>Add Type</span>
            </button>
          </>
        }
      />
      <Tabs defaultActiveKey="1" onChange={callback}
      >
        <Tabs.TabPane key="1" tab="Facilities">
          <Table
            dataSource={facilityListRes}
            columns={FacilitiesColumns}
            loading={loading}
            pagination={{
              pageSize,
              onShowSizeChange: (c, size) => {
                setPageSize(size);
              }
            }}
          />
        </Tabs.TabPane>

        <Tabs.TabPane key="2" tab="Types">
          <Table
            dataSource={typeListRes}
            columns={TypeColumns}
            pagination={{
              pageSize,
              onShowSizeChange: (c, size) => {
                setPageSize(size);
              }
            }}
          />
        </Tabs.TabPane>
      </Tabs>
      <Drawer
        open={FVisible}
        className="big-d2"
        title="Add Facility"
        onClose={() => { setFVisible(false) }}
        destroyOnClose={true}
      >
        <div className='form-elements'>
          <AddFacilityForm type="add" cVisibleInfo={FVisible} typeList={typeListRes} onSubmit={onFinishFacility} />
        </div>
      </Drawer>
      <Drawer
        open={cVisible}
        className="big-d2"
        title="Update Facility"
        onClose={() => { setcVisible(false) }}
        destroyOnClose={true}
      >
        <div className=''>
          {cVisible && <UpdateFacilityForm
            initialValues={{ permit_day: "Sunday", ...selectedItem }}
            permitDayTimeInfo={permitDayTime}
            permitDayTimeInfo2={PermitDayTime2}
            primeDayPermitDayTimeInfo={primeDayPermitDayTime}
            typeList={typeListRes}
            onSubmitUpdate={onFinishUpdateFacility}
          />}
        </div>
      </Drawer>
      <Drawer
        open={Tvisible}
        className="s-drawer"
        title="Add Type"
        onClose={() => { setTvisible(false) }}
      >
        <div className='form-elements'>
          <TypeForm type="add" cVisibleInfo={Tvisible} onSubmit={onFinishType} />
        </div>
      </Drawer>
    </>
  )
}
export default Facilities
