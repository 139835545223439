import React, { useEffect, useState } from 'react'
// import $ from 'jquery';
import { Table, Switch, Tabs, Button, Drawer, Tag } from 'antd'
import SectionTitle from '../../components/AllInApp/section-title'
import { FiPlus, FiTrash, FiEdit } from 'react-icons/fi'
import apiClient from '../../services/axios'
import ApiConfig from '../../services/ApiConfig'
import apiFormDataClient from '../../services/axios-formdata'
import HomepageBanner from '../../components/AllInApp/Website/HomepageBanner'
import AddFacility from '../../components/AllInApp/Website/AddFacility'
import RemoveModal from '../../common/RemoveModal'


const Index = () => {
  const [visible, setVisible] = useState(false);
  const [Fvisible, setFvisible] = useState(false);
  const [Uvisible, setUVisible] = useState(false);
  const [selectedTab, setselectedTab] = useState('1');
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [bannerListInfo, setbannerListInfo] = useState(null);
  const [facilitiesRes, setfacilitiesRes] = useState(null);
  const [statusPopupVisible, setstatusPopupVisible] = useState(false);
  const [deletePopupVisible, setDeletePopupVisibe] = useState(false);
  const [deleteFacilityVisibe, setDeleteFacilityVisibe] = useState(false);
  const [statusFacilityVisibe, setStatusFacilityVisibe] = useState(false);
  const [bannerimage1, setbannerimage1] = useState(null);
  const [facilityImage, setFacilityImage] = useState(null);

  const callback = (key) => {
    setselectedTab(key)
  }

  const onToggleLeague = (e, record) => {
    setSelectedItem(record);
    setstatusPopupVisible(true);
  }

  const onToggleFacility = (e, record) => {
    setSelectedItem(record);
    setStatusFacilityVisibe(true);
  }

  const SelectedBannerImage = (values) => {
    setbannerimage1(values)
  }

  const selectedfacilityImage = (values) => {
    setFacilityImage(values)
  }

  const showUpdateBanner = (values) => {
    setSelectedItem(values)
    setUVisible(true)
  }

  const showUpdatefacility = (values) => {
    setSelectedItem(values)
    setFvisible(true)
  }

  const onDelete = async () => {
    setLoading(true);
    setDeletePopupVisibe(false);
    await apiClient.patch(`${ApiConfig.website.deleteBanner}/${selectedItem._id}`);
    setLoading(false);
    loadWebsiteInfo()
  }


  const onDeleteFacility = async () => {
    setLoading(true);
    setDeleteFacilityVisibe(false);
    await apiClient.patch(`${ApiConfig.website.deleteFacility}/${selectedItem._id}`);
    setLoading(false);
    loadFacilityInfo()
  }

  const OnstatusChange = async () => {
    setLoading(true);
    setstatusPopupVisible(false);
    await apiClient.patch(`${ApiConfig.website.updateBannerSattus}/${selectedItem._id}`);
    setLoading(false);
    loadWebsiteInfo()
  }

  const OnstatusChangeFacility = async () => {
    setLoading(true);
    setStatusFacilityVisibe(false);
    await apiClient.patch(`${ApiConfig.website.updateFacilityStatus}/${selectedItem._id}`);
    setLoading(false);
    loadFacilityInfo()
  }

  const loadWebsiteInfo = async () => {
    try {
      setLoading(true);
      const response = await apiClient.get(ApiConfig.website.getBannerList);
      setbannerListInfo(response?.data?.bannerList)
      setLoading(false);
    } catch (error) {
      setLoading(false);
      return [];
    }
  }

  const loadFacilityInfo = async () => {
    try {
      setLoading(true);
      const response = await apiClient.get(ApiConfig.website.getFacilitiesList);
      setLoading(false);
      setfacilitiesRes(response?.data?.facilitiesList)
    } catch (error) {
      setLoading(false);
      return [];
    }
  }

  const OnFinishUpload = async (values) => {
    const formdata = new FormData();
    if (values.banner_heading1) {
      formdata.append('banner_heading1', values.banner_heading1);
    }
    if (values.banner_tagline1) {
      formdata.append('banner_tagline1', values.banner_tagline1);
    }
    if (values.banner_tagline2) {
      formdata.append('banner_tagline2', values.banner_tagline2);
    }
    if (values.banner_tagline2) {
      formdata.append('banner_tagline3', values.banner_tagline3);
    }
    if (bannerimage1) {
      formdata.append('banner_image1', bannerimage1);
    }
    try {
      setLoading(true);
      if (selectedItem?._id) {
        setUVisible(false)
        await apiFormDataClient.patch(`${ApiConfig.website.updateBanner}/${selectedItem?._id}`, formdata);
      }
      else {
        setVisible(false)
        await apiFormDataClient.post(ApiConfig.website.addBanner, formdata);
      }

      setLoading(false);
      loadWebsiteInfo();
    } catch (error) {
      setLoading(false);
    }
  }

  const OnFinishAddFacility = async (values) => {

    const formdata = new FormData();
    if (values.facility_name) {
      formdata.append('facility_name', values.facility_name);
    }

    if (values.slug_name) {
      formdata.append('slug_name', values.slug_name);
    }

    if (values.content) {
      formdata.append('content', values.content);
    }

    if (facilityImage) {
      formdata.append('facility_image', facilityImage);
    }

    try {
      setLoading(true);
      if (selectedItem?._id) {
        setFvisible(false)
        await apiFormDataClient.patch(`${ApiConfig.website.updateFacility}/${selectedItem?._id}`, formdata);
      }
      else {
        setFvisible(false)
        await apiFormDataClient.post(ApiConfig.website.addFacility, formdata);
      }

      setLoading(false);
      loadFacilityInfo();
    } catch (error) {
      setLoading(false);
    }
  }





  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await apiClient.get(ApiConfig.website.getBannerList);
        setLoading(false);
        setbannerListInfo(response?.data?.bannerList)
      } catch (error) {
        setLoading(false);
        return [];
      }
    })();

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await apiClient.get(ApiConfig.website.getFacilitiesList);
        setLoading(false);
        setfacilitiesRes(response?.data?.facilitiesList)
      } catch (error) {
        setLoading(false);
        return [];
      }
    })();

    // eslint-disable-next-line
  }, [])

  const bannerColumns = [
    {
      title: "S.No",
      key: 's_no',
      render: (text, record, index) => `${index + 1}.`,
    },
    {
      title: "Heading",
      key: "banner_heading1",
      dataIndex: 'banner_heading1',
    },
    {
      title: "Tagline 1",
      key: "banner_tagline1",
      dataIndex: 'banner_tagline1',
    },
    {
      title: "Tagline 2",
      key: "banner_tagline2",
      dataIndex: 'banner_tagline2',
    },
    {
      title: "Tagline 3",
      key: "banner_tagline3",
      dataIndex: 'banner_tagline3',
    },
    {
      title: "Banner Image",
      dataIndex: "banner_image1",
      name: "banner_image1",
      render: (text, record) => {
        return <div className="team_logo" style={{ width: '200px' }}><img src={text || '/logos/logo.png'} alt={record.banner_heading1} />
        </div>
      }
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record, idx) => {
        return (
          <div className="action_btns">
            <Switch checked={record.is_active} onChange={(e, event) => onToggleLeague(e, record, event)} />
            <Button onClick={() => { setSelectedItem(record); setDeletePopupVisibe(true); }} className='btn_edit' type="link" icon={<FiTrash />} />
            <Button onClick={() => showUpdateBanner(record)} className='btn_edit' type="link" icon={<FiEdit />} />
          </div>
        )
      }
    }
  ]

  const Facilitiesolumns = [
    {
      title: "S.No",
      key: 's_no',
      render: (text, record, index) => `${index + 1}.`,
    },
    {
      title: "Name",
      key: "facility_name",
      dataIndex: 'facility_name',
    },
    {
      title: "Slug Name",
      key: "slug_name",
      dataIndex: 'slug_name',
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      render: (text, record, idx) => {
        return (
          <div className="action_btns">
            <Tag style={{ cursor: 'pointer' }} color={text ? '#4BB543' : '#e40'}>{text ? 'Active' : 'Inactive'}</Tag>
          </div>
        )
      }
    },
    {
      title: "Facility Image",
      dataIndex: "facility_image",
      name: "facility_image",
      render: (text, record) => {
        return <div className="team_logo" style={{ width: '200px' }}><img src={text || '/logos/logo.png'} alt={record.banner_heading1} />
        </div>
      }
    },
    {
      title: "Action",
      key: 'action',
      render: (record) => (
        <>
          <Switch checked={record.is_active} onChange={(e, event) => onToggleFacility(e, record, event)} />
          <Button onClick={() => { setSelectedItem(record); setDeleteFacilityVisibe(true); }} className='btn_edit' type="link" icon={<FiTrash />} />
          <Button onClick={() => showUpdatefacility(record)} className='btn_edit' type="link" icon={<FiEdit />} />
        </>
      ),
    },
  ]




  const onAdd = () => {
    setVisible(true)
  }

  const onAddFacility = () => {
    setFvisible(true)
  }


  return (
    <>
      <RemoveModal
        description={selectedItem?.is_active ? 'Are you sure you want to mark this banner to hide?' : 'Are you sure you want to show the banner?'}
        open={statusPopupVisible}
        onSubmit={OnstatusChange}
        onCancel={() => setstatusPopupVisible(false)}
      />
      <RemoveModal
        description={selectedItem?.is_active ? `Are you sure you want to mark this ${selectedItem?.facility_name} to hide?` : `Are you sure you want to show the ${selectedItem?.facility_name}?`}
        open={statusFacilityVisibe}
        onSubmit={OnstatusChangeFacility}
        onCancel={() => setStatusFacilityVisibe(false)}
      />
      <RemoveModal
        description="Are you sure, you want to delete this banner"
        open={deletePopupVisible}
        onSubmit={onDelete}
        onCancel={() => setDeletePopupVisibe(false)}
      />
      <RemoveModal
        description="Are you sure, you want to delete this facility"
        open={deleteFacilityVisibe}
        onSubmit={onDeleteFacility}
        onCancel={() => setDeleteFacilityVisibe(false)}
      />
      <SectionTitle title="Website" />
      <Tabs
        defaultActiveKey={selectedTab}
        activeKey={selectedTab}
        onChange={callback}
        tabBarExtraContent={
          <>
            <button hidden={selectedTab === '2' || selectedTab === '3'} onClick={onAdd} className="btn btn-default btn-rounded btn-icon bg-blue-500 hover:bg-blue-600 text-white space-x-1">
              <FiPlus className="stroke-current text-white" size={16} />
              <span>Add Banner</span>
            </button>
            <button hidden={selectedTab === '1' || selectedTab === '2'} onClick={onAddFacility} className="btn btn-default btn-rounded btn-icon bg-blue-500 hover:bg-blue-600 text-white space-x-1">
              <FiPlus className="stroke-current text-white" size={16} />
              <span>Add Facility</span>
            </button>
          </>
        }
      >
        <Tabs.TabPane key="1" tab="Homepage Banner">
          <Table
            loading={loading}
            pagination={false}
            dataSource={bannerListInfo}
            columns={bannerColumns}
          />
        </Tabs.TabPane>

        <Tabs.TabPane key="2" tab="Events">

        </Tabs.TabPane>
        <Tabs.TabPane key="3" tab="Facilities">
          <Table
            loading={loading}
            pagination={false}
            dataSource={facilitiesRes}
            columns={Facilitiesolumns}
          />
        </Tabs.TabPane>
      </Tabs>
      <Drawer
        title="Add Homepage Banner"
        className="register_form"
        open={visible}
        onClose={() => setVisible(false)}
      >
        {visible && <HomepageBanner onSubmit={OnFinishUpload} activeBannerImage={SelectedBannerImage} />}
      </Drawer>
      <Drawer
        title="Update Homepage Banner"
        className="register_form"
        open={Uvisible}
        onClose={() => setUVisible(false)}
      >
        {Uvisible && <HomepageBanner currentItem={selectedItem} onSubmit={OnFinishUpload} activeBannerImage={SelectedBannerImage} />}
      </Drawer>
      <Drawer
        title="Add Facility"
        className="register_form"
        open={Fvisible}
        onClose={() => setFvisible(false)}
      >
        {Fvisible && <AddFacility activeFacilityImage={selectedfacilityImage} onSubmit={OnFinishAddFacility} />}
      </Drawer>

      <Drawer
        title="Update Facility"
        className="register_form"
        open={Fvisible}
        onClose={() => setFvisible(false)}
      >
        {Fvisible && <AddFacility currentItem={selectedItem} activeFacilityImage={selectedfacilityImage} onSubmit={OnFinishAddFacility} />}
      </Drawer>
    </>
  )
}
export default Index
