import React from 'react'
import {useSelector, shallowEqual} from 'react-redux'
import {Link} from 'react-router-dom'

const Logo = () => {
  const {config} = useSelector(
    (state) => ({
      config: state.config
    }),
    shallowEqual
  )
  const {name} = {...config}
  return (
    <div className="uppercase font-bold text-base tracking-wider flex flex-row items-center justify-start w-full whitespace-no-wrap text-white">
      <Link
        to="/login"
        className="flex flex-row items-center justify-start space-x-2">        
        <img src="imgs/logo_black.png" alt={name} title={name} />
      </Link>
    </div>
  )
}

export default Logo
