import React, { useState, useEffect } from 'react'
// import $ from 'jquery';
import store from 'store'
import { Tabs } from 'antd'
import Personal from './Personal'

const CreateEmployee = ({ OnProfileapiCall }) => {


  const [selectedTab, setselectedTab] = useState('1');
  const [user, setUser] = useState({});

  const callback = (key) => {
    setselectedTab(key)
  }

  console.log("selectedTab", selectedTab)

  useEffect(() => {
    (async () => {
      const userInfo = store.get('user.data');
      setUser({ ...userInfo });
    })();
  }, [])

  const personalApiData = [
    {
      "first_name": user?.first_name,
      "last_name": user?.last_name,
    }
  ]

  return (
    <>
      <Tabs defaultActiveKey="1" onChange={callback}
      >
        <Tabs.TabPane key={selectedTab} tab="Personal">
          <Personal apiData={personalApiData[0]} onSubmit={OnProfileapiCall} />
        </Tabs.TabPane>
      </Tabs>
    </>
  )
}

export default (CreateEmployee)