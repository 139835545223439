import React from "react";
import { Button } from "antd";

const SectionTitle = ({
  title,
  subtitle,
  right = null,
  showBookingInfo = null,
}) => {
  return (
    <div className="w-full mb-6 pt-3">
      <div className="flex flex-row items-center justify-between mb-4">
        <div className="flex flex-col">
          <div className="text-xs uppercase font-light text-grey-500">
            {title}
          </div>
          <div className="text-xl font-bold">{subtitle}</div>
        </div>
        {right}
        {showBookingInfo && (
          <Button onClick={() => showBookingInfo({createBooking:true})}>Create</Button>
        )}
      </div>
    </div>
  );
};

export default SectionTitle;
