import React, { useState, useEffect } from 'react'
import { Drawer, Table, Button, Tag } from 'antd'
import SectionTitle from '../../components/AllInApp/section-title'
// import $ from 'jquery';  
import RemoveModal from '../../common/RemoveModal'
import AddCreditForm from '../../components/AllInApp/Credit/AddForm'
import UpdateCreditForm from '../../components/AllInApp/Credit/UpdateForm'
import { getDateFormat } from '../../utils';
import { FiPlus, FiEdit, FiRefreshCcw } from 'react-icons/fi'
import apiClient from '../../services/axios'
import ApiConfig from '../../services/ApiConfig'
import apiFormDataClient from '../../services/axios-formdata'
import moment from 'moment'

const Index = () => {
  const [visible, setVisible] = useState(false);
  const [uVisible, setUVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [creditListRes, setCreditListRes] = useState(null);
  const [deletePopupVisible, setDeletePopupVisibe] = useState(false);

  const showUpdateCredit = (values) => {
    setSelectedItem(values)
    setUVisible(true)
  }

  const onAdd = () => {
    setVisible(true);
  }

  const onSubmitStatusChange = async () => {
    await apiClient.patch(`${ApiConfig.credit.updateStatus}/${selectedItem._id}`);
    loadCredit();
    setDeletePopupVisibe(false);
  }

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await apiClient.get(ApiConfig.credit.GetCredit);
        setCreditListRes(response?.data?.creditList)
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [])

  const onFinishUpdateform = async (values) => {
    let todayDate = new Date()
    const currentDate = getDateFormat({ date: todayDate, format: "YYYY-MM-DD" })
    const formdata = new FormData();
    formdata.append('player', values.players);
    formdata.append('credit_value', values.credit_value * 100);
    formdata.append('current_date', currentDate);
    formdata.append('type', values?.type);
    try {
      setLoading(true);
      await apiFormDataClient.put(ApiConfig.credit.updateCredit + `/${selectedItem._id}`, formdata);
      setLoading(false);
      setUVisible(false);
      loadCredit();
    } catch (error) {
      setLoading(false);
    }
  }

  const onFinishAddform = async (values) => {
    setVisible(false);
    let todayDate = new Date()
    const currentDate = getDateFormat({ date: todayDate, format: "YYYY-MM-DD" })
    const formdata = new FormData();
    formdata.append('player', values.players);
    formdata.append('credit_value', values.credit_value * 100);
    formdata.append('current_date', currentDate);
    formdata.append('type', values?.type);
    try {
      setLoading(true);
      await apiFormDataClient.post(ApiConfig.credit.addCredit, formdata);
      setLoading(false);

      loadCredit();
    } catch (error) {
      setLoading(false);
    }
  }

  const loadCredit = async () => {
    try {
      setLoading(true);
      const response = await apiClient.get(ApiConfig.credit.GetCredit);
      setCreditListRes(response?.data?.creditList)
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const getFormattedDate = (dateTime) => {
    return moment(dateTime).format('MMM D, YYYY')
  }

  const CreditColumns = [
    {
      title: "S.No",
      key: 's_no',
      render: (text, record, index) => `${index + 1}.`,
    },
    {
      title: "Account Name",
      render: (text, record, idx) => {
        return (
          <div className="action_btns">
            {record?.player?.name} <Tag style={{ cursor: 'pointer' }} color={record?.is_active ? '#4BB543' : '#e40'}>{record?.is_active ? 'Active' : 'Inactive'}</Tag>
          </div>
        )
      },
      sorter: (a, b) => a.player?.name.localeCompare(b.player?.name),
    },
    {
      title: "Last Activity Date",
      dataIndex: 'last_modified',
      key: 'last_modified',
      render: (text) => getFormattedDate(text),
      sorter: (a, b) => a?.last_modified.localeCompare(b?.last_modified),
    },
    {
      title: "Credit Balance",
      key: 'balance',
      dataIndex: 'balance',
      sorter: (a, b) => a.balance - b.balance,
      render: (text) => {
        return (
          <span>
            {`$${Number(text).toFixed(2)}`}
          </span>
        )
      },
    },
    {
      title: "Action",
      key: 'action',
      render: (record) => (
        <>
          <Button onClick={() => showUpdateCredit(record)} className='btn_edit' type="link" icon={<FiEdit />} />
          <Button onClick={() => { setSelectedItem(record); setDeletePopupVisibe(true); }} type="link" icon={<FiRefreshCcw />} />
        </>
      ),
    },
  ]

  return (
    <>
      <RemoveModal
        description={`Are you sure you want to change status for ${selectedItem?.player?.name}?`}
        open={deletePopupVisible}
        onSubmit={onSubmitStatusChange}
        onCancel={() => setDeletePopupVisibe(false)}
      />
      <SectionTitle
        title="Credit"
        right={
          <>
            <button onClick={onAdd} className="btn btn-default btn-rounded btn-icon bg-blue-500 hover:bg-blue-600 text-white space-x-1">
              <FiPlus className="stroke-current text-white" size={16} />
              <span>Add Credit</span>
            </button>
          </>
        } />
      <Table
        loading={loading}
        pagination={false}
        dataSource={creditListRes}
        columns={CreditColumns}
      />
      <Drawer
        open={visible}
        className="s-drawer"
        title="Add Credit"
        onClose={() => { setVisible(false) }}
        destroyOnClose={true}
      >
        <div className='form-elements'>
          <AddCreditForm
            cVisibleInfo={visible}
            type="add"
            onFinishApi={onFinishAddform}
          />
        </div>
      </Drawer>
      <Drawer
        open={uVisible}
        className="s-drawer"
        title="Update Credit"
        destroyOnClose={true}
        onClose={() => { setUVisible(false) }}
      >
        <div className='form-elements'>
          <UpdateCreditForm
            cVisibleInfo={uVisible}
            type="update"
            currentItem={selectedItem}
            onFinishApi={onFinishUpdateform}
          />
        </div>
      </Drawer>
    </>
  )
}
export default Index
