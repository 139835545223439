import React, { useEffect } from "react";
import styled from 'styled-components';
import $ from 'jquery';

const DaySwitchComp = styled.div`
    #day-switch-checkbox {
      z-index: 1000;
      opacity: 0;
      height: 100%;
      width: 100%;
      position: absolute;
      cursor: pointer;
    }
    .toggle {
      height: 30px;
      width: 60px;
      display: grid;
      place-items: center;
      position: relative;
      cursor: pointer;
      background: linear-gradient(to top, #3fccff, #1c71d2);
      border-radius: 9999px;
      box-shadow: 7px 7px 10px #00000018;
    }
    #graphic {
      width: 25px;
      position: absolute;
      right: 12%;
      transform-origin: center;
      transform-box: fill-box;
      transition: all 0.5s ease-in-out;
    }
    #graphic > * {
      fill: #ffc750;
      transition: all 0.5s ease-in-out;
      transform-origin: center;
      transform-box: fill-box;
    }
    
    #croissant {
      transform: rotate(360deg);
      opacity: 0;
    }
    
    #day-switch-checkbox:checked + .toggle {
      background: linear-gradient(to top, #0a5b8d, #011638);
    }
    
    #day-switch-checkbox:checked + .toggle #graphic {
      transform: translateX(-90%) rotate(360deg);
    }
    
    #day-switch-checkbox:checked + .toggle #sung {
      opacity: 0;
    }
    
    #day-switch-checkbox:checked + .toggle #croissant {
      transform: scale(1.2);
      opacity: 1;
    }  
`
const DaySwitch2 = ({ mode = "", onChange }) => {

  useEffect(() => {
    const mode = window.localStorage.getItem('mode');
    if (mode === 'dark') {
      setTimeout(() => {
        $('#day-switch-checkbox').prop('checked', true);
        onChange({ target: { checked: true } });
      }, 100);
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <DaySwitchComp className="daynight mr-2" hidden>
        <label style={{ position: "relative" }} htmlFor="checkbox">
          <input onChange={onChange} type="checkbox" name="day-switch-checkbox" id="day-switch-checkbox" />
          <div className="toggle">
            <svg id="graphic" viewBox="0 0 132.29 132.29" xmlns="http://www.w3.org/2000/svg">
              <g id="sung">
                <path id="sun" d="m99.722 66.146a33.576 33.576 0 0 1-33.576 33.576 33.576 33.576 0 0 1-33.576-33.576 33.576 33.576 0 0 1 33.576-33.576 33.576 33.576 0 0 1 33.576 33.576" />
                <g id="sunshine" transform="matrix(.92602 0 0 .92602 -10.774 -58.541)">
                  <path transform="matrix(0 .58145 -.59067 0 145.26 159.26)" d="m-19.4 32.282h-45.867l22.934-39.722z" />
                  <path transform="matrix(-.41115 .41115 -.41766 -.41766 109.64 196.03)" d="m-19.4 32.282h-45.867l22.934-39.722z" />
                  <path transform="matrix(-.58145 0 0 -.59067 58.451 196.84)" d="m-19.4 32.282h-45.867l22.934-39.722z" />
                  <path transform="matrix(-.41115 -.41115 .41766 -.41766 21.685 161.22)" d="m-19.4 32.282h-45.867l22.934-39.722z" />
                  <path transform="matrix(0 -.58145 .59067 0 20.875 110.03)" d="m-19.4 32.282h-45.867l22.934-39.722z" />
                  <path transform="matrix(.41115 -.41115 .41766 .41766 56.495 73.269)" d="m-19.4 32.282h-45.867l22.934-39.722z" />
                  <path transform="matrix(.58145 0 0 .59067 107.68 72.459)" d="m-19.4 32.282h-45.867l22.934-39.722z" />
                  <path transform="matrix(.41115 .41115 -.41766 .41766 144.45 108.08)" d="m-19.4 32.282h-45.867l22.934-39.722z" />
                </g>
              </g>
              <path id="croissant" d="m55.461 34.281a33.587 33.587 0 0 0-22.874 31.832 33.587 33.587 0 0 0 33.587 33.587 33.587 33.587 0 0 0 30.441-19.395 31.187 31.187 0 0 1-17.268 5.2174 31.187 31.187 0 0 1-31.187-31.187 31.187 31.187 0 0 1 7.3022-20.053z" />
            </svg>
          </div>
        </label>
      </DaySwitchComp>
    </>
  )
}

export default DaySwitch2;