import React, { useState, useEffect } from 'react'
import { Table, Input, Tabs, Tag, Drawer, Button } from 'antd'
// import { FiFile, } from 'react-icons/fi'
import SectionTitle from '../../components/AllInApp/section-title'
import ViewMessage from '../../components/AllInApp/Inquiry/ViewMessage'
import EnquiryNotes from '../../components/AllInApp/Inquiry/EnquiryNotes'
import ChangeInquiryStatus from '../../common/ChangeInquiryStatus'
import { getDateFormat } from '../../utils/'
import { phoneNumberNational } from '../../utils/parser'
import apiClient from '../../services/axios'
import ApiConfig from '../../services/ApiConfig'
import { FiEye, FiFileText } from 'react-icons/fi'

const InquiryComponent = () => {
  const [loading, setLoading] = useState(false);
  const [cVisible, setcVisible] = useState(false);
  const [MVisible, setMVisible] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [enquiryListInfo, setenquiryListInfo] = useState(null);
  const [messageListRes, setmessageListRes] = useState(null);
  const [filteredEnquiry, setfilteredEnquiry] = useState(null);
  const [selectedStatus, setselectedStatus] = useState(null);
  const [selectedItem, setselectedItem] = useState(null);
  const [search, setSearch] = useState('');
  const [satusPopupVisible, setstatusPopupVisible] = useState(false);
  const [selectedTab, setselectedTab] = useState('1');


  console.log("26", selectedTab)


  const callback = (key) => {

    if (key === '1') {
      setselectedTab(key)
      setfilteredEnquiry(enquiryListInfo?.filter(x => x.status !== null))
    }
    else if (key === '2') {
      setselectedTab(key)
      setfilteredEnquiry(enquiryListInfo?.filter(x => x.status === 'Open'))
    }
    else if (key === '3') {
      setselectedTab(key)
      setfilteredEnquiry(enquiryListInfo?.filter(x => x.status === 'Pending'))
    }
    else if (key === '4') {
      setselectedTab(key)
      setfilteredEnquiry(enquiryListInfo?.filter(x => x.status === 'Closed'))
    }
  }

  useEffect(() => {
    if (selectedTab === '1') {
      setselectedTab(selectedTab)
      setfilteredEnquiry(enquiryListInfo?.filter(x => x.status !== null))
    }
    // else if (selectedTab === 2) {
    //   setselectedTab(selectedTab)
    //   setfilteredEnquiry(enquiryListInfo?.filter(x => x.status === 'Open'))
    // }
    // else if (selectedTab === 3) {
    //   setselectedTab(selectedTab)
    //   setfilteredEnquiry(enquiryListInfo?.filter(x => x.status === 'Pending'))
    // }
    // else if (selectedTab === 4) {
    //   setselectedTab(selectedTab)
    //   setfilteredEnquiry(enquiryListInfo?.filter(x => x.status === 'Closed'))
    // }
  }, [enquiryListInfo, selectedTab])

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await apiClient.get(ApiConfig.enquiry.getEnquiry);
        setenquiryListInfo(response?.data?.enquiryList)
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [])

  const loadEnquiries = async () => {
    try {
      setLoading(true);
      const response = await apiClient.get(ApiConfig.enquiry.getEnquiry);
      console.log("81", response)
      setenquiryListInfo(response?.data?.enquiryList)
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }


  const OntogglestatusChange = (e, record, val) => {
    setselectedItem(record);
    setstatusPopupVisible(true);
    setselectedStatus(val)
  }


  const onFinishSaveNotes = async (values) => {
    try {
      setMVisible(true)
      setselectedItem(values)
      setLoading(true);
      const response = await apiClient.post(`${ApiConfig.enquiry.saveNotes}`, values);
      if (response) {
        loadNotes(values?.enquiry)
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const loadNotes = async (values) => {
    try {
      // setMVisible(true)
      setselectedItem(selectedItem)
      setLoading(true);
      const response = await apiClient.get(`${ApiConfig.enquiry.getMessage}/${values}`);
      setmessageListRes(response?.data?.messageList)
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }


  const showNotes = async (values) => {
    try {
      setMVisible(true)
      setselectedItem(values)
      setLoading(true);
      const response = await apiClient.get(`${ApiConfig.enquiry.getMessage}/${values?._id}`);
      setmessageListRes(response?.data?.messageList)
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const onChangeStatus = async (values) => {
    try {
      setLoading(true);
      const payload = {
        "status": values?.status
      }
      const response = await apiClient.patch(`${ApiConfig.enquiry.updateStatus}/${selectedItem?._id}`, payload);
      if (response) {
        setstatusPopupVisible(false);
        callback(selectedTab)
        loadEnquiries()
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }

  }


  const showMessageDrawer = async (values) => {
    setselectedItem(values);
    setcVisible(true)
  }

  const userColumns = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      render: (text, record, key) => (key + 1).toString()
    },
    {
      title: "Name",
      dataIndex: ['name'],
      key: "name",
    },
    {
      title: "Email Id",
      dataIndex: ['emailid'],
      key: "emailid",
    },
    {
      title: "Phone Number",
      dataIndex: 'mobile',
      key: 'mobile',
      render: (text) => {
        let formattedPhoneNumber = '-'
        const phoneNumber = phoneNumberNational(text)
        if (phoneNumber) formattedPhoneNumber = phoneNumber
        return <span>{formattedPhoneNumber}</span>
      },
    },
    {
      title: "Subject",
      dataIndex: ['subject'],
      key: "subject",
    },
    {
      title: "Enquiry Received At",
      key: "created_at",
      render: (text, record) => {
        return (
          <>
            {getDateFormat(record?.created_at)}
          </>
        )
      }
    },
    {
      title: "Status",
      dataIndex: ['status'],
      key: "status",
      render: (text, record) => (
        <>
          {record?.status === 'Open' && (
            <>
              <Tag color="magenta" onClick={(e, event) => OntogglestatusChange(e, record, 'Open')}>Open</Tag>
            </>
          )}
          {record?.status === 'Pending' && (
            <>
              <Tag color="#2db7f5" onClick={(e, event) => OntogglestatusChange(e, record, 'Pending')}>Pending</Tag>
            </>
          )}
          {record?.status === 'Closed' && (
            <>
              <Tag color="#87d068" onClick={(e, event) => OntogglestatusChange(e, record, 'Closed')}>Closed</Tag>
            </>
          )}
        </>
      )
    },
    {
      title: "Action",
      render: (text, record) => {
        return (
          <>
            <Button
              onClick={() => { showMessageDrawer(record) }}
              type="link"
              className="h40"
              title="View Message"
              icon={<FiEye />}
            />
            <Button
              onClick={() => { showNotes(record) }}
              type="link"
              className="h40"
              title="View Message"
              icon={<FiFileText />}
            />
          </>
        )
      }
    }
  ];


  return (
    <>
      <ChangeInquiryStatus
        description={`Are you sure you want to update status for ${selectedItem?.name} enquiry`}
        open={satusPopupVisible}
        onSubmit={onChangeStatus}
        onCancel={() => setstatusPopupVisible(false)}
        currentStatus={selectedStatus}
      />
      <SectionTitle
        title="Inquiries"
        right={
          <>
            <div className="ant-item"><Input value={search} onChange={e => setSearch(e.target.value)} style={{ minWidth: "300px" }} placeholder="Search by name or email or mobile.." /></div>
          </>
        }
      />
      <Drawer
        open={cVisible}
        className="s-drawer"
        title={`View Inquiry - ${(selectedItem?.name)}`}
        onClose={() => { setcVisible(false) }}
        destroyOnClose={true}
      >
        <div className='form-elements'>
          <ViewMessage
            cVisibleInfo={cVisible}
            currentItem={selectedItem}
          />
        </div>
      </Drawer>
      <Drawer
        open={MVisible}
        placement="right"
        className="big-d2 notes-drawer-style"
        title={`Add Notes - ${(selectedItem?.name)}`}
        onClose={() => { setMVisible(false) }}
        destroyOnClose={true}
      >
        {/* <div className='form-elements'> */}
        <EnquiryNotes
          cVisibleInfo={MVisible}
          currentItem={selectedItem}
          notesInfo={messageListRes}
          onFinish={onFinishSaveNotes}
        />
        {/* </div> */}
      </Drawer>
      <Tabs
        defaultActiveKey={selectedTab}
        activeKey={selectedTab}
        onChange={callback}
      >
        <Tabs.TabPane key={1} tab="All">
          <Table
            dataSource={filteredEnquiry?.filter(e => (e.name.toLowerCase().indexOf(search?.toLowerCase()) >= 0) || (e.mobile.toLowerCase().indexOf(search?.toLowerCase()) >= 0) || (e.emailid.toLowerCase().indexOf(search?.toLowerCase()) >= 0))}
            columns={userColumns}
            loading={loading}
            pagination={{
              pageSize,
              onShowSizeChange: (c, size) => {
                setPageSize(size);
              }
            }}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key={2} tab="Open">
          <Table
            dataSource={filteredEnquiry?.filter(e => (e.name.toLowerCase().indexOf(search?.toLowerCase()) >= 0) || (e.mobile.toLowerCase().indexOf(search?.toLowerCase()) >= 0) || (e.emailid.toLowerCase().indexOf(search?.toLowerCase()) >= 0))}
            columns={userColumns}
            loading={loading}
            pagination={{
              pageSize,
              onShowSizeChange: (c, size) => {
                setPageSize(size);
              }
            }}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key={3} tab="Pending">
          <Table
            dataSource={filteredEnquiry?.filter(e => (e.name.toLowerCase().indexOf(search?.toLowerCase()) >= 0) || (e.mobile.toLowerCase().indexOf(search?.toLowerCase()) >= 0) || (e.emailid.toLowerCase().indexOf(search?.toLowerCase()) >= 0))}
            columns={userColumns}
            loading={loading}
            pagination={{
              pageSize,
              onShowSizeChange: (c, size) => {
                setPageSize(size);
              }
            }}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key={4} tab="Closed">
          <Table
            dataSource={filteredEnquiry?.filter(e => (e.name.toLowerCase().indexOf(search?.toLowerCase()) >= 0) || (e.mobile.toLowerCase().indexOf(search?.toLowerCase()) >= 0) || (e.emailid.toLowerCase().indexOf(search?.toLowerCase()) >= 0))}
            columns={userColumns}
            loading={loading}
            pagination={{
              pageSize,
              onShowSizeChange: (c, size) => {
                setPageSize(size);
              }
            }}
          />
        </Tabs.TabPane>
      </Tabs>


    </>
  )
}


export default InquiryComponent
