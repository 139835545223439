import React, { useRef } from 'react'
import { Tooltip, Form, Input, Button } from 'antd'
import moment from 'moment-timezone'
import store from 'store'
import PerfectScrollbar from 'react-perfect-scrollbar'
import style from './style.module.scss'
import "antd/dist/antd.css";
import Loading from './Loading'

export const getDateFormat2 = (props, flag) => {
  const dFormat = "YYYY-MM-DD hh:mm A";
  if (typeof props === 'object') {
    const { date, format = dFormat, isTime } = props || {}
    if (!date) return;
    if (isTime) return moment(date).format(`${format} hh:mm a`)
    return moment(date).format(format)
  } else {
    if (flag) return props ? moment(props) : moment();
    return props ? moment(props).format(`${dFormat}`) : moment();
  }
}

const scrollToRef = ref => window.scrollTo(500, ref.current.offsetTop)


const TypeComponent = ({ cVisibleInfo, currentItem, notesInfo, onFinish }) => {

  const [form] = Form.useForm();

  const myRef = useRef(null)
  const executeScroll = () => scrollToRef(myRef)

  const userInfo = store.get('user.data');

  const inputRef = useRef(null)

  const userId = userInfo?._id

  let todayDate = new Date()
  const currentDate = getDateFormat2({ date: todayDate, format: "YYYY-MM-DD hh:mm A" })

  const onSubmitInfo = (values) => {
    const payload = {
      "enquiry": currentItem?._id,
      "users": userId,
      "comments": values?.comments,
      "created": currentDate
    }
    onFinish(payload)
    form.resetFields()
    inputRef.current.focus()
  }

  const notesinfo = notesInfo


  if (!notesinfo) return <Loading />

  const groups = {}

  // eslint-disable-next-line
  notesinfo.forEach(function (notesdata) {
    const date = notesdata?.created.split('T')[0]
    if (!groups[date]) {
      groups[date] = []
    }
    groups[date].push(notesdata)
    return groups
  }, {})

  const groupArrays = Object.keys(groups).map(created => {
    return {
      created,
      listed_data: groups[created],
    }
  })

  const length = groupArrays?.length || 0



  return (
    <>
      <div className='form-elements'>
        <div style={{ backgroundColor: 'white', padding: '20px' }}>
          <div className="card" hidden={length === 0}>
            <div className="card-body">
              <div className="notes-section height-600 d-flex flex-column justify-content-center">
                <PerfectScrollbar
                  onScrollY={container => console.log(`scrolled to: ${container.scrollTop}.`)}
                >
                  <div className={style.contentWrapper} ref={myRef}>
                    {groupArrays?.map(item => (
                      <div key={item._id}>
                        <div className="text-gray-4 font-size-14 text-center text-capitalize">
                          {getDateFormat2({ date: item?.created, format: "MMM D, YYYY" })}


                          {/* {moment.tz(item?.created, 'Canada/Eastern').format(' MMM D, YYYY')} */}
                        </div>
                        {item.listed_data.map(item1 => (
                          <div
                            className={`${style.message} ${style.answer} ${style.answermarginbottom}`}
                          >
                            <div className={style.messageContent}>
                              <div className="text-gray-4 text-capitalize" style={{ fontSize: '13px' }}>
                                {item1?.name}
                                <Tooltip
                                  title={getDateFormat2({ date: item?.created, format: "MMM D [@] hh:mm A" })}
                                  // title={moment.format('MMM D [@] hh:mm A')}
                                  placement="topLeft"
                                >
                                  &nbsp;{' '}
                                  <span title="">
                                    {getDateFormat2({ date: item?.created, format: "MMM D [@] hh:mm A" })}
                                    {/* {moment
                                    .tz(item1?.created, 'Canada/Eastern')
                                    .format('MMM D [@] hh:mm A')} */}
                                  </span>
                                </Tooltip>
                              </div>
                              <div>{item1?.comments}</div>
                            </div>

                            <div className={`${style.messageAvatar} kit__utils__avatar`}>
                              <img src="images/avatar-2.png" alt="You" />
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </PerfectScrollbar>
              </div>
            </div>
          </div>
          <div className="card" hidden={length !== 0}>
            <div className="card-body">
              <div className="height-600 d-flex flex-column justify-content-end">
                <div className="row" style={{ marginBottom: '20px' }}>
                  <div className="col-md-12">
                    <div className="d-flex flex-wrap m-1">
                      <div className="flex-grow-1">
                        <div
                          className="text-center font-weight-normal font-size-15 text-gray-5"
                          style={{ padding: '0 0 200px 0' }}
                        >
                          No notes to show
                          <br />
                          <br />
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card" style={{ marginTop: '5px' }}>
            <div className="card-body">
              <Form layout="vertical" onFinish={onSubmitInfo} form={form} hideRequiredMark>
                <Form.Item
                  name="comments"
                  rules={[{ required: true, message: "Message Required" }]}
                >
                  <div className="input-group mb-3" style={{ flexWrap: 'nowrap' }}>
                    <Input
                      type="text"
                      placeholder="Enter the message"
                      aria-label="Recipient's username"
                      aria-describedby="button-addon2"

                      ref={inputRef}
                      size="large"
                      required
                    />
                    <div className="form-footer">
                      <div className="input-group-append ">
                        <Button
                          className="btn-md btn-primary h40 custom-btn-notes"
                          type="primary"
                          htmlType="submit"
                          onClick={executeScroll}
                        >
                          Add Notes
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>

      </div>

    </>
  )
}

export default (TypeComponent)