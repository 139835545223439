import React, { useState, useEffect } from 'react'
import { Table, Tag } from 'antd'
import SectionTitle from '../../components/AllInApp/section-title'
import { getDateFormat2 } from '../../utils/'
import apiClient from '../../services/axios'
import { phoneNumberNational } from '../../utils/parser'
import ApiConfig from '../../services/ApiConfig'

const CustomerComponent = () => {
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [customerListInfo, setcustomerListInfo] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await apiClient.get(ApiConfig.customers.getCustomer);
        setcustomerListInfo(response?.data?.customerList)
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [])

  const userColumns = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      render: (text, record, key) => (key + 1).toString()
    },
    {
      title: "Name",
      render: (text, record) => {
        return (
          <>
            {(record?.name)}
          </>
        )
      }
    },
    {
      title: "Email Id",
      dataIndex: ['emailid'],
      key: "emailid",
    },
    {
      title: "Phone Number",
      dataIndex: 'mobile',
      key: 'mobile',
      render: (text) => {
        let formattedPhoneNumber = '-'
        const phoneNumber = phoneNumberNational(text)
        if (phoneNumber) formattedPhoneNumber = phoneNumber
        return <span>{formattedPhoneNumber}</span>
      },
    },
    {
      title: "Booked Amount",
      render: (text, record, idx) => {
        // eslint-disable-next-line no-unused-expressions
        <> </>
      }
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      render: (text, record, idx) => {
        return (
          <div className="action_btns">
            <Tag style={{ cursor: 'pointer' }} color={text ? '#4BB543' : '#e40'}>{text ? 'Active' : 'Inactive'}</Tag>
          </div>
        )
      }
    },
    {
      title: "Created Date",
      key: "created_at",
      render: (text, record) => {
        return (
          <>
            {getDateFormat2(record?.created_at)}
          </>
        )
      }
    },
  ];


  return (
    <>
      <SectionTitle
        title="Customers"
      />
      <Table
        dataSource={customerListInfo}
        columns={userColumns}
        loading={loading}
        pagination={{
          pageSize,
          onShowSizeChange: (c, size) => {
            setPageSize(size);
          }
        }}
      />

    </>
  )
}


export default CustomerComponent
