export const ApiConfig = {
  auth: {
    login: "auth/login",
    logout: "auth/logout",
    forgotPassword: "auth/forgot-password",
    resetPassword: "auth/reset-password",
    changePassword: "auth/change-password",
  },
  users: {
    getUser: "users/userlist",
    createUser: "superadmin/create",
    updateStatus: "superadmin/updatestatus",
    deleteUser: "superadmin/delete",
  },
  customers: {
    getCustomer: "customer/getcustomer",
  },

  profile: {
    load: "profile",
    update: "profile/update",
  },
  facilities: {
    addType: "facilities/addtype",
    getType: "facilities/gettype",
    updateTypeStatus: "facilities/updatetypestatus",
    addFacility: "facilities/addfacility",
    updateFacility: "facilities/updatefacility",
    getFacility: "facilities/getfacility",
    getBookingList: "facilities/getbookinglist",
    deleteFacilities: "facilities/deletefacility",
    getPlayer: "facilities/getplayerlist",
  },
  coupon: {
    getBookingCoupon: "coupon/getbookingcoupon",
    getActiveCouponList: "coupon/getactivecoupon",
    getInactiveCouponList: "coupon/getinactivecoupon",
    addCoupon: "coupon/addcoupon",
    updateCoupon: "coupon/updatecoupon",
    updateStatusInactive: "coupon/updatestatusinactive",
    updateStatus: "coupon/updatestatus",
    deleteCoupon: "coupon/deletecoupon",
    checkExistingCoupon: "coupon/checkcoupon",
    checkDuplicates: "coupon/checkduplicates",
  },
  player: {
    playerSearch: 'player/teams/player-search',
  },
  credit: {
    addCredit: 'credit/add-credit',
    updateCredit: 'credit/update-credit',
    updateStatus: 'credit/updatestatus',
    GetCredit: 'credit/get-credit',
    GetCreditLogs: 'credit/get-credit-log',
  },
  website: {
    getBannerList: 'website-manager/getbannerlist',
    addBanner: 'website-manager/addbanner',
    updateBanner: 'website-manager/updatebanner',
    updateBannerSattus: 'website-manager/updatestatus',
    deleteBanner: 'website-manager/deletebanner',
    addFacility: 'website-manager/addfacility',
    getFacilitiesList: 'website-manager/getfacilitieslist',
    updateFacilityStatus: 'website-manager/updatefacilitystatus',
    deleteFacility: 'website-manager/deletefacility',
    updateFacility: "website-manager/updatefacility",
    getBookingInfo: "website/getbookinginfo"
  },
  website_payment: {
    createRefund: "website/refund",
    retrieveRefund: "website/refund-details",
  },
  enquiry: {
    getEnquiry: 'enquiry/getenquirylist',
    updatedToRead: 'enquiry/updatetoread',
    updateStatus: 'enquiry/updatestatus',
    getMessage: 'enquiry/getmessage',
    saveNotes: 'enquiry/savenotes',
  },
  payments: {
    getPaymentInfo: 'payment/getpayment'
  }
}

export default ApiConfig;