import React, { useState } from 'react'
import { Input } from 'antd'
import PropTypes from 'prop-types'

const NumberInput = ({ value, onChange, max, precision, decimalPlaces = 2, integerOnly, ...otherProps }) => {
  const [number, setNumber] = useState(null)

  const onNumberChange = (changedValue) => {
    let newNumber
    const dec = changedValue.indexOf('.')
    const tooLong = changedValue.length > dec + (decimalPlaces + 1)
    const invalidNum =
      Number.isNaN(parseFloat(changedValue)) || parseFloat(parseFloat(changedValue).toFixed(precision)) > parseInt(max, 10)
    if ((dec >= 0 && tooLong) || invalidNum) {
      newNumber = changedValue.slice(0, -1)
    } else {
      newNumber = changedValue;
      if (number) {
        let isVal = true;
        const len = changedValue.toString().indexOf('.') >= 0 ? number.toString().length + 2 : number.toString().length;
        isVal = changedValue.toString().split('.')[0].length <= max.toString().length
        newNumber = isVal ? changedValue : changedValue.slice(0, len);
      }
    }
    if (Number.isNaN(number) || Number.isNaN(newNumber)) {
      setNumber(null)
      onChange(null)
      return
    }
    setNumber(integerOnly ? parseInt(newNumber, 10) : newNumber)
    onChange(integerOnly ? parseInt(newNumber, 10) : newNumber)
  }

  return (
    <Input
      type="number"
      value={value}
      // onKeyDown={(e) => {
      //   if (integerOnly && e.key === '.') {
      //     setNumber(value)
      //     onChange(value)
      //   }
      // }}
      onChange={(e) => onNumberChange(e.target.value)}
      {...otherProps}
    />
  )
}

NumberInput.propTypes = {
  max: PropTypes.any,
  precision: PropTypes.number,
}

NumberInput.defaultProps = {
  max: 9999.99,
  precision: 2,
}

export default NumberInput
