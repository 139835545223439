import React, { useEffect, useState } from 'react'
import { Button, Form, Input, message } from 'antd'
// import { InboxOutlined } from '@ant-design/icons'
import UploderInput from '../../../../common/uploader-input';

const { TextArea } = Input;

const HomepageBanner = ({ onSubmit, activeFacilityImage, currentItem }) => {

  const [logo, setLogo] = useState(null);
  const [isLogoDeleted, setIsLogoDeleted] = useState(false);
  const [hideButton, sethideButton] = useState(true);
  const [form] = Form.useForm();

  const onFileChange = (file) => {
    console.log('file', file);
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    else {
      if (file) {
        sethideButton(false)
        activeFacilityImage(file)
        setLogo(file)
      } else {
        setIsLogoDeleted(true)
        sethideButton(true)
      }
    }
  }

  // const props = {
  //   name: 'file',
  //   beforeUpload: (file) => {
  //     const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  //     if (!isJpgOrPng) {
  //       message.error('You can only upload JPG/PNG file!');
  //     }
  //     return isJpgOrPng;
  //   },
  //   customRequest: ({ file, onSuccess }) => {
  //     setTimeout(() => {
  //       onSuccess('ok')
  //     }, 0)
  //   },
  //   onChange: (e) => {
  //     if (e.file.status === 'done') {
  //       activeBannerImage(e.file.originFileObj)
  //       window.scheduleFile = e.file.originFileObj;
  //     }
  //   },
  //   onRemove: () => {
  //     window.scheduleFile = null
  //   }
  // }

  useEffect(() => {
    if (currentItem) {
      form.setFieldsValue({
        facility_name: currentItem?.facility_name,
        slug_name: currentItem?.slug_name,
        content: currentItem?.content,
      })
      setLogo(currentItem?.facility_image)
    }
    if(currentItem?.facility_image && isLogoDeleted===false)
    {
      sethideButton(false)
    }
    
    if(isLogoDeleted===true)
    {
      sethideButton(true)
    }
  }, [form, currentItem, isLogoDeleted])


  console.log("isLogoDeleted", isLogoDeleted)

  return (
    <>

      <Form
        layout="vertical"
        form={form}
        requiredMark={false}
        onFinish={onSubmit}
        initialValues={
          {
            ...currentItem
          }
        }
      >
        <div className="flex flex-col lg:flex-row w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4">
          <div className="w-full lg:w-4/4">
            <Form.Item
              label="Facility Name"
              name="facility_name"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Input placeholder="Enter the facility name" />
            </Form.Item>
            <Form.Item
              label="Slug Name"
              name="slug_name"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Input placeholder="Enter the slug name" />
            </Form.Item>
            <Form.Item
              name="content"
              label="Content"
              className="mt-3 textarea-input"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <TextArea style={{ height: '200px' }} rows={8} placeholder="Enter your content" maxLength={800} />
            </Form.Item>
            <Form.Item
              name="facility_image"
              label="Facility Image"
              className="upload-img-input"
            >
              <UploderInput url={logo} accept="image/*" onChange={onFileChange} />
            </Form.Item>
            <div className="form-footer">
              <Button hidden={hideButton} htmlType="submit" type="primary">{currentItem?._id ? 'Update' : 'Add'} Facility</Button>
            </div>
          </div>
        </div>
      </Form>
    </>
  )
}
export default HomepageBanner
