import React, { useEffect, useState } from 'react'
import { Button, Modal, Form, message } from 'antd'
import NumberInput from '../../common/NumberInputWithoutArrow'


const RemoveModal = ({
  description,
  open,
  onCancel,
  info,
  currency,
  onSubmit,
  loading

}) => {

  const [form] = Form.useForm();

  useEffect(() => {
  }, [description])

  useEffect(() => {
    if (info) {
      form.setFieldsValue({
        amount: (info?.payment_info?.amount / 100).toFixed(2),
        refund_amount: (info?.payment_info?.amount / 100).toFixed(2)
      })
    }
  }, [info, form])

  const [currentRefundAmount, setcurrentRefundAmount] = useState(null);
  const [hideButton, sethideButton] = useState(false);



  const onValuesChange = (changedValues, AllValues) => {
    if (changedValues?.refund_amount) {
      if ((changedValues?.refund_amount * 100) > (info?.payment_info?.amount)) {
        sethideButton(true)
        message.error('Please enter the amount less than billing amount!');
      }
      else {
        sethideButton(false)
        setcurrentRefundAmount(changedValues?.refund_amount * 100)
      }
    }
  }

  const OnfinishApiCall = async () => {
    const fields = form.getFieldsValue()
    if (currentRefundAmount === null) {
      const payload = {
        "refund_amount": fields?.refund_amount * 100,
        "bookingID": info?.bookingId,
        "refId": info?._id,
      }
      onSubmit(payload)
    }
    else {
      const payload = {
        "refund_amount": currentRefundAmount,
        "bookingID": info?.bookingId,
        "refId": info?._id,
      }
      onSubmit(payload)
    }
  }


  return (
    <Modal className="removal-modal" title={description} open={open} onCancel={onCancel} footer={null}>
      <Form
        form={form}
        layout="vertical"
        onValuesChange={onValuesChange}
        requiredMark={false}
        onFinish={OnfinishApiCall}
      >
        <Form.Item
          label="Charged Amount"
          name="amount"
          rules={[{ required: true, message: "This field is required" }]}
        >
          {/* <InputNumber
            addonBefore="$"
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            placeholder="Enter the Amount"
            disabled={true}
          /> */}
          <NumberInput
            size="medium"
            placeholder="Enter Amount"
            addonBefore="$"
            max={99999.99}
            precision={2}
            style={{ width: '50%' }}
            disabled={true}
          />
        </Form.Item>
        <Form.Item name="refund_amount" label="Refund Amount">
          <NumberInput
            size="medium"
            placeholder="Refund Amount"
            addonBefore="$"
            max={99999.99}
            precision={2}
            style={{ width: '50%' }}
          />
        </Form.Item>
        {/* <Form.Item
          label="Refund Amount"
          name="refund_amount"
          rules={[{ required: true, message: "This field is required" }]}
        >
          <InputNumber
            addonBefore="$"
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            placeholder="Enter the Amount"
            precision={2}
            decimalSeparator="."
          />
        </Form.Item> */}

        <Form.Item className="text-right">
          <Button type="primary" hidden={hideButton} htmlType="submit" size="large" loading={loading}>
            Refund
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default RemoveModal
