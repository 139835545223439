import React, { useState } from 'react'
import { Input } from 'antd'
import PropTypes from 'prop-types'

const NumberInput = ({ value, onChange, max, precision, ...otherProps }) => {
  const [number, setNumber] = useState(null)

  const onNumberChange = (changedValue) => {
    let newNumber
    const dec = changedValue.indexOf('.')
    const tooLong = changedValue.length > dec + 3
    const invalidNum =
      Number.isNaN(parseFloat(changedValue)) || parseFloat(changedValue).toFixed(precision) > max
    if ((dec >= 0 && tooLong) || invalidNum) {
      newNumber = changedValue.slice(0, -1)
    } else {
      newNumber = changedValue
    }
    if (Number.isNaN(number) || Number.isNaN(newNumber)) {
      setNumber(null)
      onChange(null)
      return
    }
    setNumber(newNumber)
    onChange(newNumber)
  }

  return (
    <Input
      type="number"
      value={value}
      onChange={(e) => onNumberChange(e.target.value)}
      {...otherProps}
    />
  )
}

NumberInput.propTypes = {
  max: PropTypes.number,
  precision: PropTypes.number,
}

NumberInput.defaultProps = {
  max: 9999.99,
  precision: 2,
}

export default NumberInput
