import React, { useState, useEffect } from 'react'
import { Button, Form, Input, Select, InputNumber, Switch, Space } from 'antd'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { timeIntervals } from '../../../../utils'

const Option = Select


const FacilityUUpdateFormComponent = (
  {
    initialValues, permitDayTimeInfo, permitDayTimeInfo2, primeDayPermitDayTimeInfo, typeList, onSubmitUpdate
  }
) => {

  const weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const weekDaysPrimeTime = [
    {
      "label": "Sunday",
      "value": 0
    },
    {
      "label": "Monday",
      "value": 1
    },
    {
      "label": "Tuesday",
      "value": 2
    },
    {
      "label": "Wednesday",
      "value": 3
    },
    {
      "label": "Thursday",
      "value": 4
    },
    {
      "label": "Friday",
      "value": 5
    },
    {
      "label": "Saturday",
      "value": 6
    },
  ];

  const [permitDayTime, setPermitDayTime] = useState(permitDayTimeInfo)
  const [selectedPermitDuration, setselectedPermitDuration] = useState(null);
  const [permitDurationInitialValue, setpermitDurationInitialValue] = useState(null);
  const [showPermitDay, setshowPermitDay] = useState(false);
  const [ShowPrimeTime, setShowPrimeTime] = useState(false);
  const [defaultload, setdefaultload] = useState(true);
  const [currentIndex, setcurrentIndex] = useState(null);
  const [currentChange, setcurrentChange] = useState(null);
  const [selectedItem, setselectedItem] = useState(null);
  const [form] = Form.useForm();


  useEffect(() => {
    if (initialValues && defaultload === true) {
      form.setFieldsValue({
        type_name: initialValues?.type_name,
        name: initialValues?.name,
        regular_rate: initialValues?.regular_rate,
        day_availability: initialValues?.regular?.day_availability,
        is_prime_time: initialValues?.is_prime_time,
      })

      if (initialValues?.is_prime_time) {
        const declaredInfo = initialValues?.prime_time?.permit_day_time
        const destructuredPrimeTime = declaredInfo?.map((e, index) => (
          { day: weekDaysPrimeTime.filter(x => x?.value === e?.day)[0]?.label, fromTime: e.timings[0].from, toTime: e.timings[0].to, amount: e.rate }
        ))
        form.setFieldsValue({
          options: destructuredPrimeTime,
        })
      }

      if (initialValues?.regular?.day_availability === 'custom') {
        setshowPermitDay(true)
      }
      else if (initialValues?.regular?.day_availability === 'unlimited') {
        setshowPermitDay(false)
      }

      if (initialValues?.is_prime_time === true) {
        setShowPrimeTime(true)
      }
      else if (initialValues?.is_prime_time === false) {
        setShowPrimeTime(false)
      }

      if (permitDurationInitialValue === null) {
        const newVal = initialValues?.permit_duration
        let formattedNewVal
        if (newVal === '1') {
          formattedNewVal = newVal ? `${newVal} Month` : `${newVal} Months`
        }
        if (newVal > '1') {
          formattedNewVal = newVal ? `${newVal} Months` : `${newVal} Month`
        }
        else if (newVal === 'Unlimited') {
          formattedNewVal = 'Unlimited'
        }
        setpermitDurationInitialValue(formattedNewVal)
        setselectedPermitDuration(newVal)
      }
      else if (permitDurationInitialValue === undefined) {
        const newVal = initialValues?.permit_duration
        let formattedNewVal
        if (newVal === '1') {
          formattedNewVal = newVal ? `${newVal} Month` : `${newVal} Months`
        }
        else if (newVal === 'Unlimited') {
          formattedNewVal = 'Unlimited'
        }
        setpermitDurationInitialValue(formattedNewVal)
        setselectedPermitDuration(newVal)
      }

    }
    else {
      // else code
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultload, form, initialValues, weekDays])


  useEffect(() => {
    // const getFieldsArray = form.getFieldsValue()
    // const optionsArray = getFieldsArray?.options
    // console.log("line no 133", optionsArray)
    if (currentChange === 'day') {
      //empty condition
    }
    else if (currentChange === 'fromtime') {
      const fields = form.getFieldsValue()
      if (fields?.options?.length > 0) {
        const array = fields?.options
        for (let i = 0; i < array?.length; i += 1) {
          for (let j = 0; j < array?.length; j += 1) {
            if (array[i]?.day === array[j]?.day) {
              if ((array[i]?.fromTime < array[j]?.fromTime) && (array[i]?.toTime > array[j]?.fromTime)) {
                const selectedItemArray = array.filter(item => item.fromTime !== selectedItem?.options[currentIndex]?.fromTime)
                const res = [...selectedItemArray, { day: selectedItem?.options[currentIndex]?.day, fromTime: '', toTime: selectedItem?.options[currentIndex]?.toTime, amount: selectedItem?.options[currentIndex]?.amount, }]
                form.setFieldsValue({
                  options: res,
                })
              }
            }
          }
        }
      }
    }
    else if (currentChange === 'totime') {
      const fields = form.getFieldsValue()
      if (fields?.options?.length > 0) {
        const array = fields?.options
        for (let i = 0; i < array?.length; i += 1) {
          for (let j = 0; j < array?.length; j += 1) {
            if (array[i]?.day === array[j]?.day) {
              if ((array[i]?.toTime > array[j]?.toTime) && (array[i]?.fromTime < array[j]?.toTime)) {
                const selectedItemArray = array.filter(item => item.toTime !== selectedItem?.options[currentIndex]?.toTime)
                const res = [...selectedItemArray, { day: selectedItem?.options[currentIndex]?.day, toTime: '', fromTime: selectedItem?.options[currentIndex]?.fromTime, amount: selectedItem?.options[currentIndex]?.amount, }]
                form.setFieldsValue({
                  options: res,
                })
              }
            }
          }
        }
      }
    }

  }, [currentChange, currentIndex, selectedItem, form, weekDaysPrimeTime])


  const permitDuration = [
    {
      "duration": 1,
    },
    {
      "duration": 2,
    },
    {
      "duration": 3,
    }, {
      "duration": 4,
    },
    {
      "duration": 5,
    },
    {
      "duration": 6,
    },
    {
      "duration": 7,
    },
    {
      "duration": 8,
    },
    {
      "duration": 9,
    },
    {
      "duration": 10,
    },
    {
      "duration": 11,
    },
    {
      "duration": 12,
    },
    {
      "duration": 'Unlimited',
    }

  ]

  const onChangePermitDuration = (values) => {

    setpermitDurationInitialValue(values)
  }

  const onSelectDay = (week) => {
    const filter = permitDayTime?.filter(x => x.day === week)
    if (filter?.length === 0) {
      const secondArray = permitDayTimeInfo2?.filter(y => y.day === week)[0]
      const combineInfo = permitDayTime.concat(secondArray)
      const sorter = {
        "sunday": 0,
        "monday": 1,
        "tuesday": 2,
        "wednesday": 3,
        "thursday": 4,
        "friday": 5,
        "saturday": 6
      }
      combineInfo.sort(function sortByDay(a, b) {
        let day1 = a.day.toLowerCase();
        let day2 = b.day.toLowerCase();
        return sorter[day1] - sorter[day2];
      });
      setPermitDayTime([...combineInfo])
    }
    else {
      const idx = permitDayTime.findIndex(e => e.day === week);
      if (idx >= 0) {
        permitDayTime[idx].show = !permitDayTime[idx].show;
      }
      setPermitDayTime([...permitDayTime])
    }

  }

  const onChangeTime = (item, time, type) => {
    const idx = permitDayTime.findIndex(e => e.day === item.day);
    permitDayTime[idx][type] = time;
    setPermitDayTime([...permitDayTime])
  }

  const onSubmitInfo = (values) => {
    let payload = null
    if (values?.is_prime_time === true) {
      const permit_day_time = permitDayTime.filter(e => e.show).map(e => ({
        day: weekDays.indexOf(e.day),
        timings: [{
          from: e.from,
          to: e.to
        }]
      }))
      const destructuredPrimeTime = values?.options.map((e, index) => (
        {
          day: weekDays.indexOf(e.day),
          timings: [{
            from: e.fromTime,
            to: e.toTime
          }],
          rate: e.amount,
        })
      )
      payload = {
        "facilitiesId": initialValues?._id,
        "type_name": values?.type_name,
        "name": values?.name,
        "is_prime_time": true,
        "permit_duration": permitDurationInitialValue,
        "regular_rate": values?.regular_rate,
        "regular": {
          "day_availability": values?.day_availability,
          "permit_day_time": permit_day_time
        },
        "prime_time": {
          "day_availability": 'custom',
          "permit_day_time": destructuredPrimeTime
        }
      }

    }
    else {
      if (values?.day_availability === 'custom') {
        const permit_day_time = permitDayTime.filter(e => e.show).map(e => ({
          day: weekDays.indexOf(e.day),
          timings: [{
            from: e.from,
            to: e.to
          }]
        }))
        payload = {
          "facilitiesId": initialValues?._id,
          "type_name": values?.type_name,
          "name": values?.name,
          "is_prime_time": false,
          "permit_duration": permitDurationInitialValue,
          "regular_rate": values?.regular_rate,
          "regular": {
            "day_availability": values?.day_availability,
            "permit_day_time": permit_day_time
          }
        }
      }
      else {
        const permit_day_time = permitDayTime.map(e => ({
          day: weekDays.indexOf(e.day),
          timings: [{
            from: e.from,
            to: e.to
          }]
        }))
        payload = {
          "facilitiesId": initialValues?._id,
          "type_name": values?.type_name,
          "name": values?.name,
          "is_prime_time": false,
          "permit_duration": permitDurationInitialValue,
          "regular_rate": values?.regular_rate,
          "regular": {
            "day_availability": values?.day_availability,
            "permit_day_time": permit_day_time
          }
        }
      }
    }
    setpermitDurationInitialValue(null)
    onSubmitUpdate(payload)
  }

  const onValuesChange = (changedValues, AllValues) => {
    setdefaultload(false)
    if (changedValues?.day_availability === 'custom') {
      setshowPermitDay(true)
    }
    else if (changedValues?.day_availability === 'unlimited') {
      setshowPermitDay(false)
    }
    else if (changedValues?.is_prime_time === true) {
      setShowPrimeTime(true)
    }
    else if (changedValues?.is_prime_time === false) {
      setShowPrimeTime(false)
    }
    else if (changedValues?.options) {
      setselectedItem(AllValues)
    }
  }

  const SortableItem = SortableElement(({ field, remove, index }) => {
    return (
      <div key={`element-${index}`}>
        <Space align="baseline">
          <Form.Item
            {...field}
            name={[field.name, 'day']}
            fieldKey={[field.fieldKey, 'day']}
            rules={[{ required: true, message: 'Missing Day' }]}
          >
            <Select
              placeholder="Select Day"
              size="large"
              style={{ width: '130px' }}
              onChange={e => onChangeDay(e, field.key)}
            >
              {weekDaysPrimeTime?.map((mval, index) => (
                <Option key={mval?.label} value={mval?.label}>{mval?.label} </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={[field.name, 'fromTime']}
            fieldKey={[field.fieldKey, 'fromTime']}
            rules={[{ required: true, message: 'Missing From Time' }]}

          >
            <Select
              className='fromTime'
              showSearch
              options={timeIntervals}
              placeholder="From"
              style={{ width: '120px' }}
              onChange={e => onChangeFromTime(e, field.key)}
            />
          </Form.Item>
          <Form.Item
            name={[field.name, 'toTime']}
            fieldKey={[field.fieldKey, 'toTime']}
            rules={[{ required: true, message: 'Missing From Time' }]}
          >
            <Select
              className='toTime'
              showSearch
              options={timeIntervals}
              placeholder="To"
              style={{ width: '120px' }}
              onChange={e => onChangeToTime(e, field.key)}
            />
          </Form.Item>
          <Form.Item
            name={[field.name, 'amount']}
            fieldKey={[field.fieldKey, 'amount']}
            rules={[{ required: true, message: 'Missing Rate  ' }]}
          >
            <InputNumber
              onChange={e => onChangeamount(e, field.key)}
              addonBefore="$"
              className='toTime'
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              style={{ width: '145px', marginLeft: '15px', marginTop: '-10px' }}
            />
          </Form.Item>
          <MinusCircleOutlined onClick={() => remove(field.name)} />
        </Space>
      </div>
    )
  })

  const SortableList = SortableContainer(({ items, remove }) => {
    return (
      <div>
        {items.map((field, index) => (
          <SortableItem key={`item-${field}`} index={index} field={field} remove={remove} />
        ))}
      </div>
    )
  })

  const onChangeDay = (value, key) => {
    setcurrentChange('day')
    setcurrentIndex(key)
  }

  const onChangeFromTime = (value, key) => {
    setcurrentChange('fromtime')
    setcurrentIndex(key)
  }

  const onChangeToTime = (value, key) => {
    setcurrentChange('totime')
    setcurrentIndex(key)
  }
  const onChangeamount = (value, key) => {
    setcurrentChange('amount')
    setcurrentIndex(key)
  }

  console.log("onChangePermitDuration", selectedPermitDuration?.length)

  return (
    <div className='form-elements'>
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmitInfo}
        onValuesChange={onValuesChange}
        requiredMark={false}
      >
        <div className='form-title'>Facility Information</div>
        <Form.Item
          label="Type Name"
          name="type_name"
          rules={[{ required: true, message: "This field is required" }]}
        >
          <Select
            showSearch
            disabled
            placeholder="Select a type"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
          >
            {typeList?.filter(x => x.is_active === true)?.map((mval, index) => (
              <Option key={mval?._id} value={mval?._id}>{mval?.type_name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Court Name"
          name="name"
          rules={[{ required: true, message: "This field is required" }]}
        >
          <Input placeholder="Enter a court name" />
        </Form.Item>
        <div className="ant-form-item">
          <div className="ant-row ant-form-item-row">
            <div className="ant-col ant-form-item-label">
              <label htmlFor="permit_duration" className="ant-form-item-required" title="Permit Duration">Permit Duration</label>
            </div>
            <div style={{ color: 'grey', marginBottom: '10px' }}>How long in the futures, do you want customers to book?.</div>
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input">
                <div className="ant-form-item-control-input-content">
                  <Select
                    placeholder="Select From the List"
                    size="large"
                    style={{ width: '100%' }}
                    onChange={(e) => onChangePermitDuration(e)}
                    value={permitDurationInitialValue}
                  >
                    {permitDuration?.map((mval, index) => (
                      <Option key={mval.duration} >{mval.duration ? `${mval.duration} Month` : `${mval.duration} Month`}{mval.duration !== 1 ? 's' : ''} </Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Form.Item
          name="regular_rate"
          label="Regular Rate"
          rules={[{ required: true, message: "This field is required" }]}
        >
          <InputNumber
            addonBefore="$"
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            style={{ width: '652px' }}
            placeholder="Enter the Rate"
          />
        </Form.Item>
        <div className='form-title'>Booking Availability</div>
        <Form.Item
          name="day_availability"
          label="Regular Hours"
          className='time_range'
        >
          <Select
            placeholder="Select From the List"
            size="large"
          >
            <Option key="custom" value="custom">Custom Days</Option>
            <Option key="unlimited" value="unlimited">Unlimited 24/7</Option>
          </Select>
        </Form.Item>
        {showPermitDay === true && (
          <>
            <Form.Item
              name="permit_day"
              label="Permit Day"
              className='time_range'
            >
              {weekDays.map((week, k) => {
                return (
                  <>
                    <Button key={k.toString()} onClick={() => onSelectDay(week)} className={`mr-3${permitDayTime?.filter(pp => pp.day === week)[0]?.show ? ' selected' : ''}`} shape="circle">{week?.slice(0, 2)}</Button>
                  </>
                )
              })}
              {
                permitDayTime.filter(e => e.show).map((pp, k) => (
                  <Form.Item
                    key={k.toString()}
                    name="time_range"
                    className="time_range_block"
                    label={pp?.day?.slice(0, 2)}
                  >
                    <Form.Item>
                      <Select className='fromTime' showSearch value={pp.from} onChange={(e) => onChangeTime(pp, e, 'from')} options={timeIntervals} placeholder="From" />
                    </Form.Item>
                    <Form.Item>
                      <Select className='toTime' showSearch value={pp.to} onChange={(e) => onChangeTime(pp, e, 'to')} options={timeIntervals} placeholder="To" />
                    </Form.Item>
                  </Form.Item>
                ))
              }
            </Form.Item>
          </>)}
        <Form.Item
          name="is_prime_time"
          label="Prime Time"
          valuePropName="checked"
        >
          <Switch checkedChildren="Yes" unCheckedChildren="No" />
        </Form.Item>
        {ShowPrimeTime === true && (
          <>
            <div className="flex-list-small" style={{ float: 'right' }}>
              <Form.List name="options">
                {(fields, { add, remove, move }) => (
                  <>
                    <SortableList
                      items={fields}
                      remove={remove}
                      onSortEnd={e => move(e.oldIndex, e.newIndex)}
                      useDragHandle
                      distance={2}
                      lockAxis="y"
                    />
                    <Form.Item style={{ marginTop: '-60px' }}>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        icon={<PlusOutlined />}
                      >
                        Add
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </div>
          </>
        )}
        {/* {ShowPrimeTime === true && (
          <>
            <Form.Item
              name="prime_time_permit_day"
              className='time_range'
            >
              {weekDaysPrimeTime.map((week, k) => {
                return (
                  <>
                    <Button key={k.toString()} onClick={() => onSelectPrimeTimeDay(week)} className={`mr-3${primeTimePermitDayTime?.filter(pp => pp.day === week)[0]?.show ? ' selected' : ''}`} shape="circle">{week?.slice(0, 2)}</Button>
                  </>
                )
              })}
              {
                primeTimePermitDayTime.filter(e => e.show).map((pp, k) => (
                  <Form.Item
                    key={k.toString()}
                    name="prime_time_time_range"
                    className="time_range_block"
                    label={pp?.day?.slice(0, 2)}
                  >
                    <Form.Item>
                      <Select className='fromTime' showSearch value={pp.from} onChange={(e) => onChangePrimeTime(pp, e, 'from')} options={timeIntervals} placeholder="From" />
                    </Form.Item>
                    <Form.Item>
                      <Select className='toTime' showSearch value={pp.to} onChange={(e) => onChangePrimeTime(pp, e, 'to')} options={timeIntervals} placeholder="To" />
                    </Form.Item>
                    <Form.Item>
                      <InputNumber
                        onChange={(e) => onChangePrimeTime(pp, e, 'rate')}
                        value={pp.rate}
                        addonBefore="$"
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        style={{ width: '145px', marginLeft: '15px' }}
                      />
                    </Form.Item>
                  </Form.Item>
                ))
              }
            </Form.Item>
          </>)} */}
        <div className="form-footer" style={{ textAlign: "center" }}>
          <Button type="primary" className="h40" htmlType="submit">Update Facility</Button>
        </div>
      </Form>
    </div>
  )
}


export default (FacilityUUpdateFormComponent)