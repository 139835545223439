import React, { useEffect, useState } from 'react'
import { Button, Form, Input, message } from 'antd'
// import { InboxOutlined } from '@ant-design/icons'
import UploderInput from '../../../../common/uploader-input';

const HomepageBanner = ({ onSubmit, activeBannerImage, currentItem }) => {

  const [logo, setLogo] = useState(null);
  const [isLogoDeleted, setIsLogoDeleted] = useState(false);
  const [hideButton, sethideButton] = useState(true);
  const [form] = Form.useForm();

  const onFileChange = (file) => {
    console.log('file', file);
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    else {
      if (file) {
        sethideButton(false)
        activeBannerImage(file)
        setLogo(file)
      } else {
        setIsLogoDeleted(true)
        sethideButton(true)
      }
    }
    // if(file===false)
    // {
    //   setIsLogoDeleted(true)
    // }
  }

  // const props = {
  //   name: 'file',
  //   beforeUpload: (file) => {
  //     const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  //     if (!isJpgOrPng) {
  //       message.error('You can only upload JPG/PNG file!');
  //     }
  //     return isJpgOrPng;
  //   },
  //   customRequest: ({ file, onSuccess }) => {
  //     setTimeout(() => {
  //       onSuccess('ok')
  //     }, 0)
  //   },
  //   onChange: (e) => {
  //     if (e.file.status === 'done') {
  //       activeBannerImage(e.file.originFileObj)
  //       window.scheduleFile = e.file.originFileObj;
  //     }
  //   },
  //   onRemove: () => {
  //     window.scheduleFile = null
  //   }
  // }

  useEffect(() => {
    if (currentItem) {
      form.setFieldsValue({
        banner_heading1: currentItem?.banner_heading1,
        banner_tagline1: currentItem?.banner_tagline1,
        banner_tagline2: currentItem?.banner_tagline2,
        banner_tagline3: currentItem?.banner_tagline3,
      })
      setLogo(currentItem?.banner_image1)
    }

    if(currentItem?.banner_image1 && isLogoDeleted===false)
    {
      sethideButton(false)
    }
    
    if(isLogoDeleted===true)
    {
      sethideButton(true)
    }
    
  }, [form, currentItem, isLogoDeleted])


  console.log("isLogoDeleted", isLogoDeleted)

  return (
    <>

      <Form
        layout="vertical"
        form={form}
        requiredMark={false}
        onFinish={onSubmit}
        initialValues={
          {
            ...currentItem
          }
        }
      >
        <div className="flex flex-col lg:flex-row w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4">
          <div className="w-full lg:w-4/4">
            <Form.Item
              label="Banner Heading 1"
              name="banner_heading1"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Input placeholder="Enter the banner heading 1" />
            </Form.Item>
            <Form.Item
              label="Banner Tagline 1"
              name="banner_tagline1"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Input placeholder="Enter the banner tagline 1" />
            </Form.Item>
            <Form.Item
              label="Banner Tagline 2"
              name="banner_tagline2"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Input placeholder="Enter the banner tagline 2" />
            </Form.Item>
            <Form.Item
              label="Banner Tagline 3"
              name="banner_tagline3"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Input placeholder="Enter the banner tagline 3" />
            </Form.Item>
            {/* {!currentItem?._id && (
              <>
                <Form.Item
                  label="Heading"
                  name="banner_image1"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  <Upload.Dragger
                    {...props}
                    multiple={false}
                    maxCount={1}
                    accept="image/*"
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                  </Upload.Dragger>
                </Form.Item>
              </>)} */}
            <Form.Item
              name="banner_image1"
              label="Banner Image"
              className="upload-img-input"
            >
              <UploderInput url={logo} accept="image/*" onChange={onFileChange} />
            </Form.Item>
            <div className="form-footer">
              <Button hidden={hideButton} htmlType="submit" type="primary">{currentItem?._id ? 'Update' : 'Add'} Banner</Button>
            </div>
          </div>
        </div>
      </Form>

    </>
  )
}
export default HomepageBanner
