/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
// import $ from 'jquery';
import store from 'store'
import SectionTitle from '../../components/AllInApp/section-title'
import { UnderlinedTabs } from '../../components/tabs'
import SecuritySettings from '../../components/AllInApp/SecuritySettings'
import PersonalProfile from '../../components/AllInApp/PersonalProfile'
import Widget from '../../components/AllInApp/widgets/widgets2'
import ApiConfig from '../../services/ApiConfig'
import apiFormDataClient from '../../services/axios-formdata'

const UserProfile = () => {

  const [clearFields, setclearFields] = useState(false);
  const [user, setUser] = useState({});

  useEffect(() => {
    (async () => {
      const userInfo = store.get('user.data');
      setUser({ ...userInfo });

    })();
  }, [])

  const ProfileapiCall = async (values) => {
    const payload = {
      "first_name": values?.first_name,
      "last_name": values?.last_name,
    }
    // setLoading(true);
    try {
      const response = await apiFormDataClient.post(ApiConfig.profile.update, payload)
      const { user } = response.data;
      store.set(`user.data`, user);
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
    }
  }

  const Changepasswordapiall = async (values) => {
    const payload = {
      "old_password": values?.old_password,
      "new_password": values?.new_password,
      "email": user?.email,
      "userid": user?._id,
    }
    // setLoading(true);
    try {
      const response = await apiFormDataClient.post(ApiConfig.auth.changePassword, payload)
      if (response) {
        setclearFields(true)
      }
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
    }
  }


  const tabs = [
    {
      index: 0,
      title: 'Profile',
      content: (
        <div className="py-4 w-full lg:w-1/2">
          <PersonalProfile
            OnProfileapiCall={ProfileapiCall}
          />
        </div>
      )
    },
    {
      index: 2,
      title: 'Security Settings',
      content: (
        <div className="py-4 w-full lg:w-1/2">
          <SecuritySettings onSubmit={Changepasswordapiall} dataClear={clearFields} />
        </div>
      )
    }
  ]

  const propsdata = { "first_name": user?.first_name, "last_name": user?.last_name }

  const getNameFirstLetters = (props) => {
    if (!props) return;
    const { first_name = '', last_name = '' } = props
    if (first_name && last_name) {
      return first_name[0].toUpperCase() + last_name[0].toUpperCase()
    } else if (first_name) {
      return first_name[0].toUpperCase() + first_name[1]?.toUpperCase()
    }
    return ''
  }

  return (
    <>
      <SectionTitle title="Profile" />

      <Widget>
        <div className="flex flex-row items-center justify-start p-4">
          <div className="flex-shrink-0 w-24">
            <div className='d-pic shadow rounded-full h-20 w-20 shadow-outline mb-2'>
              {getNameFirstLetters(propsdata)}
            </div>
          </div>
          <div className="py-2 px-2">
            <p className="no-margin-bottom text-base font-bold whitespace-no-wrap" style={{ textTransform: 'uppercase' }}>{user?.first_name} {user?.last_name}</p>
            <p className="no-margin-bottom text-sm whitespace-no-wrap">
              <span className='text-grey-500'>{user?.roles}</span>
            </p>
          </div>
        </div>

        <div className="flex flex-wrap">
          <div className="w-full p-4">
            <UnderlinedTabs tabs={tabs} />
          </div>
        </div>
      </Widget>
    </>
  )

}

export default UserProfile
