import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Form, Input } from 'antd'
import store from 'store'
import Logo from '../../components/login/logo'
import Footer from '../../components/login/footer'
import apiClient from '../../services/axios'
import ApiConfig from '../../services/ApiConfig'

const ForgotPassword = () => {
  let history = useHistory()

  const FormElem = () => {
    const [loading, setLoading] = useState(false);
    const onSubmit = async (values) => {
      setLoading(true);
      try {
        const response = await apiClient.post(ApiConfig.auth.forgotPassword, values);
        const { email } = response.data;
        localStorage.setItem('entered-email', email)
        history.push('/reset-password');
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }

    useEffect(() => {
      const token = store.get('user.accessToken');
      if (token) {
        history.replace('/dashboard')
      }
    }, [])

    return (
      <div className="flex flex-col">
        <Form
          layout="vertical"
          requiredMark={false}
          onFinish={onSubmit}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input placeholder="Enter your email" />
          </Form.Item>
          <Form.Item noStyle>
            <Button loading={loading} htmlType="submit" type="primary">Send Code</Button>
          </Form.Item>
        </Form>
      </div>
    )
  }

  return (
    <>
      <div className="w-full flex flex-row h-screen overflow-hidden auth-page-2">
        <div className="hidden lg:flex lg:flex-col w-3/4 text-white p-8 items-start justify-between relative bg-login-2">
          <Logo />
        </div>
        <div className="w-full lg:w-1/2 bg-white p-8 lg:p-24 flex flex-col items-start justify-center">
          <p className="text-2xl font-bold text-blue-500 mb-4">
            Forgot your password
          </p>
          <FormElem />
          <div className="w-full mt-5">
            <span>
              <Link className="link" to="/login">
                Back to Login?
              </Link>
            </span>
          </div>
          <div className='footer-elem'>
            <Footer />
          </div>
        </div>
      </div>
    </>
  )
}

export default ForgotPassword
