import React, { useState, useEffect } from 'react'
import { Table, Tag, Button, Drawer } from 'antd'
import store from 'store'
import { FiRefreshCcw, FiPlus, FiTrash } from 'react-icons/fi'
import SectionTitle from '../../components/AllInApp/section-title'
import AddUserForm from '../../components/AllInApp/Users/AddForm'
import RemoveModal from '../../common/RemoveModal'
import { getDateFormat2 } from '../../utils/'
import { phoneNumberNational } from '../../utils/parser'
import apiClient from '../../services/axios'
import ApiConfig from '../../services/ApiConfig'

const UserComponent = () => {
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [UserListInfo, setUserListInfo] = useState(null);
  const [cVisible, setcVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [deletePopupVisible, setDeletePopupVisibe] = useState(false);
  const [deleteVisibe, setDeleteVisibe] = useState(false);
  const userInfo = store.get('user.data');

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await apiClient.get(ApiConfig.users.getUser);
        setUserListInfo(response?.data?.userList)
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [])

  const loadUsers = async () => {
    try {
      setLoading(true);
      const response = await apiClient.get(ApiConfig.users.getUser);
      setUserListInfo(response?.data?.userList)
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const onSubmitStatusChange = async () => {
    await apiClient.patch(`${ApiConfig.users.updateStatus}/${selectedItem._id}`);
    loadUsers();
    setDeletePopupVisibe(false);
  }


  const onSubmitDeleteUser = async () => {
    await apiClient.delete(`${ApiConfig.users.deleteUser}/${selectedItem._id}`);
    loadUsers();
    setDeleteVisibe(false);
  }

  const onFinishAddform = async (values) => {
    try {
      setLoading(true);
      await apiClient.post(`${ApiConfig.users.createUser}`, values);
      setcVisible(false);
      setLoading(false);
      loadUsers();
    } catch (error) {
      setLoading(false);
    }
  }

  const userColumns = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      render: (text, record, key) => (key + 1).toString()
    },
    {
      title: "Name",
      render: (text, record) => {
        return (
          <>
            {(record?.first_name + ' ' + record?.last_name)}
            &nbsp;
            {record?.roles === 'Super Admin' && (
              <>
                <Tag style={{ cursor: 'pointer' }} color="#F99A22">Super Admin</Tag>
              </>
            )}
            {record?.roles === 'Manager' && (
              <>
                <Tag style={{ cursor: 'pointer' }} color="#1d8fd1">Manager</Tag>
              </>
            )}
            {record?.roles === 'Employee' && (
              <>
                <Tag style={{ cursor: 'pointer' }} color="#ee4400">Employee</Tag>
              </>
            )}
          </>
        )
      }
    },
    {
      title: "Email ID",
      dataIndex: ['email'],
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: 'mobile',
      key: 'mobile',
      render: (text) => {
        let formattedPhoneNumber = '-'
        const phoneNumber = phoneNumberNational(text)
        if (phoneNumber) formattedPhoneNumber = phoneNumber
        return <span>{formattedPhoneNumber}</span>
      },
    },
    // {
    //   title: "Mobile",
    //   dataIndex: ['mobile'],
    //   key: "mobile",
    // },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      render: (text, record, idx) => {
        return (
          <div className="action_btns">
            <Tag style={{ cursor: 'pointer' }} color={text ? '#4BB543' : '#e40'}>{text ? 'Active' : 'Inactive'}</Tag>
          </div>
        )
      }
    },
    {
      title: "Created Date",
      key: "created_at",
      render: (text, record) => {
        return (
          <>
            {getDateFormat2(record?.created_at)}
          </>
        )
      }
    },
    {
      title: "Action",
      key: 'action',
      render: (record) => (
        <>
          <Button
            onClick={() => {
              setSelectedItem(record);
              setDeletePopupVisibe(true);
            }}
            type="link"
            icon={<FiRefreshCcw />}
            hidden={record?._id === userInfo?._id}
          />
          {/* <Button
            type="link"
            className="h40"
            icon={<FiLock />}
            hidden={record?._id === userInfo?._id}
          /> */}
          <Button
            hidden={record?._id === userInfo?._id}
            onClick={() => { setSelectedItem(record); setDeleteVisibe(true); }}
            type="link"
            icon={<FiTrash />}
          />
        </>
      ),
    },
  ];

  const onAdd = () => {
    setSelectedItem({
      first_name: null,
      last_name: null,
      email: null,
      mobile: null,
      password: null,
      confirm_password: null,
    });
    setcVisible(true);
  }

  return (
    <>
      <RemoveModal
        description={`Are you sure you want to change status for ${selectedItem?.first_name} ${selectedItem?.last_name}?`}
        open={deletePopupVisible}
        onSubmit={onSubmitStatusChange}
        onCancel={() => setDeletePopupVisibe(false)}
      />
      <RemoveModal
        description={`Are you sure you want to delete ${selectedItem?.first_name} ${selectedItem?.last_name}?`}
        open={deleteVisibe}
        onSubmit={onSubmitDeleteUser}
        onCancel={() => setDeleteVisibe(false)}
      />
      <SectionTitle
        title="Users"
        right={
          <>
            <button onClick={onAdd} className="btn btn-default btn-rounded btn-icon bg-blue-500 hover:bg-blue-600 text-white space-x-1">
              <FiPlus className="stroke-current text-white" size={16} />
              <span>Add User</span>
            </button>
          </>
        }
      />
      <Table
        dataSource={UserListInfo}
        columns={userColumns}
        loading={loading}
        pagination={{
          pageSize,
          onShowSizeChange: (c, size) => {
            setPageSize(size);
          }
        }}
      />
      <Drawer
        open={cVisible}
        className="s-drawer"
        title="Add User"
        onClose={() => { setcVisible(false) }}
      >
        <div className='form-elements'>
          <AddUserForm
            cVisibleInfo={cVisible}
            initialValues={selectedItem}
            type="add"
            onFinishApi={onFinishAddform}
          />
        </div>
      </Drawer>
    </>
  )
}


export default UserComponent
