import React, { useEffect, useState } from "react";
import { Button, Form, Input, Select, DatePicker } from "antd";
import moment from "moment";
import apiClient from "../../../../services/axios";
import ApiConfig from "../../../../services/ApiConfig";
// import BookingForm from './BookingForm';

const Option = Select;

const FacilityFormComponent = ({
  cVisibleInfo,
  currentItem,
  facilitiesArray,
  typeArray,
  playerArray,
  timeIntervals,
  disabled = true,
}) => {
  const [form] = Form.useForm();
  const [defaultload, setdefaultload] = useState(true);
  // const [permitDayTime, setPermitDayTime] = useState([]);
  const [primeTimePermitDayTime, setprimeTimePermitDayTime] = useState([]);
  const [fromTime, setFromTime] = useState(null);
  const [toTime, setToTime] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [type, setType] = useState(null);
  const [playerName, setPlayerName] = useState(null);
  const [playerMobile, setPlayerMobile] = useState(null);
  const [slot, setSlot] = useState(null);
  const [playerEmail, setPlayerEmail] = useState(null);
  const weekDaysPrimeTime = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const weekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  console.log("currentItem", currentItem);
  console.log("primeTimePermitDayTim", primeTimePermitDayTime);

  // eslint-disable-next-line no-undef
  console.log(disabled, "21");
  const dateFormat = "ddd, DD MMM, YYYY";
  const timeIntervalsvalues = [
    {
      label: "12:00 AM",
      value: 0,
    },
    {
      label: "01:00 AM",
      value: 60,
    },
    {
      label: "02:00 AM",
      value: 120,
    },
    {
      label: "03:00 AM",
      value: 180,
    },
    {
      label: "04:00 AM",
      value: 240,
    },
    {
      label: "05:00 AM",
      value: 300,
    },
    {
      label: "06:00 AM",
      value: 360,
    },
    {
      label: "07:00 AM",
      value: 420,
    },
    {
      label: "08:00 AM",
      value: 480,
    },
    {
      label: "09:00 AM",
      value: 540,
    },
    {
      label: "10:00 AM",
      value: 600,
    },
    {
      label: "11:00 AM",
      value: 660,
    },
    {
      label: "12:00 PM",
      value: 720,
    },
    {
      label: "01:00 PM",
      value: 780,
    },
    {
      label: "02:00 PM",
      value: 840,
    },
    {
      label: "03:00 PM",
      value: 900,
    },
    {
      label: "04:00 PM",
      value: 960,
    },
    {
      label: "05:00 PM",
      value: 1020,
    },
    {
      label: "06:00 PM",
      value: 1080,
    },
    {
      label: "07:00 PM",
      value: 1140,
    },
    {
      label: "08:00 PM",
      value: 1200,
    },
    {
      label: "09:00 PM",
      value: 1260,
    },
    {
      label: "10:00 PM",
      value: 1320,
    },
    {
      label: "11:00 PM",
      value: 1380,
    },
  ];
  // console.log(form.getFieldValue(),'form.getFieldValue().from')
  // useEffect(()=>console.log(form.getFieldValue()),[form.getFieldValue().from])
  // useEffect(() => {
  //   if (cVisibleInfo === true) {
  //     if (currentItem?.is_regular_time) {
  //       form.setFieldsValue({
  //         slot_type: "regular",
  //       });
  //     }
  //     if (currentItem?.is_prime_time) {
  //       form.setFieldsValue({
  //         slot_type: "primetime",
  //       });
  //     }
  //     if (currentItem?.player) {
  //       const response =
  //         playerArray?.filter((x) => x._id === currentItem?.player) || null;
  //       let formattedPhoneNumber = "-";
  //       const phoneNumber = phoneNumberNational(response[0]?.mobile);
  //       if (phoneNumber) formattedPhoneNumber = phoneNumber;
  //       form.setFieldsValue({
  //         player_name: response[0]?.name,
  //         player_emailid: response[0]?.emailid,
  //         player_mobile: formattedPhoneNumber,
  //       });
  //     }
  //     form.setFieldsValue({
  //       type_name: currentItem?.type_name,
  //       name: currentItem?.court_name,
  //       permit_day: weekDays.indexOf(currentItem?.slot_booking_info?.day),
  //       rate: currentItem?.rate,
  //     });
  //   }
  //   // eslint-disable-line
  // });

  // const weekDayName = moment(
  //   currentItem?.slot_booking_info?.booking_date
  // ).format("dddd");

  useEffect(() => {
    if (cVisibleInfo === true && defaultload === true) {
      const temp = [];
      const temp2 = [];
      weekDays.forEach((w) => {
        temp.push({
          day: w,
          from: timeIntervals[0].value,
          to: timeIntervals[timeIntervals.length - 1].value,
          show: w === "Sunday",
        });
      });
      weekDaysPrimeTime.forEach((w) => {
        temp2.push({
          day: w,
          from: timeIntervals[0].value,
          to: timeIntervals[timeIntervals.length - 1].value,
          rate: 0,
          show: w === "Sunday",
        });
      });
      setdefaultload(false);
      // setPermitDayTime([...temp]);
      setprimeTimePermitDayTime([...temp2]);
    }
  }, [cVisibleInfo, defaultload, timeIntervals, weekDays, weekDaysPrimeTime]);

  useEffect(() => {
    (async () => {
      try {
        if (
          (fromTime || fromTime === 0) &&
          (toTime || toTime === 0) &&
          fromDate &&
          toDate &&
          type
        ) {
          const payload = {
            bookingToDate: toDate,
            booking_date: fromDate,
            from: fromTime,
            to: toTime,
            typeId: type,
          };
          const response = await apiClient.post(
            ApiConfig.website.getBookingInfo,
            payload
          );
          console.log(response?.data, "response");
          setSlot(response?.data?.facilityList);
        }
      } catch (error) {
        // setLoading(false);
      }
    })();
  }, [fromTime, toTime, fromDate, toDate, type]);
  console.log(playerName, playerMobile, slot, playerEmail);

  const onFormDataChange = (value) => {
    console.log(value, "value");
    const dFormat = "YYYY-MM-DD";
    if (value?.type_name) setType(value?.type_name);
    if (value?.booking_date)
      setFromDate(moment(value?.booking_date)?.format(`${dFormat}`));
    if (value?.booking_date_end)
      setToDate(moment(value?.booking_date_end)?.format(`${dFormat}`));
    if (value?.from) setFromTime(value?.from);
    if (value?.to) setToTime(value?.to);
    if (value?.player_name) setPlayerName(value?.player_name);
    if (value?.player_mobile) setPlayerMobile(value?.player_mobile);
    if (value?.player_emailid) setPlayerEmail(value?.player_emailid);
  };

  return (
    <>
      <div className="form-elements">
        {/* <BookingForm/> */}
        <Form
          form={form}
          layout="vertical"
          requiredMark={false}
          onValuesChange={onFormDataChange}
        >
          <div className="form-title">Create a Booking</div>
          <Form.Item
            label="Event Name"
            name="type_name"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Select
              showSearch
              disabled={disabled}
              placeholder="Select a Event"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {typeArray
                ?.filter((x) => x.is_active === true)
                ?.map((mval, index) => (
                  <Option key={mval?._id} value={mval?._id}>
                    {mval?.type_name}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <div className="form-title">Booking Info</div>
          {/* <Form.Item
            name="slot_type"
            label="Slot Type"
            className='time_range'
          >
            <Select
              placeholder="Select From the List"
              size="large"
              disabled={disabled}
            >
              <Option key="regular" value="regular">Regular Hours</Option>
              <Option key="primetime" value="primetime">Prime Time Hours</Option>
            </Select>
          </Form.Item> */}
          {/* <Form.Item
            name="rate"
            label="Rate"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <InputNumber
              addonBefore="$"
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              style={{ width: '652px' }}
              placeholder="Enter the Rate"
              disabled={disabled}
            />
          </Form.Item> */}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "45%", display: "inline-block" }}>
              <Form.Item
                name="booking_date"
                label="Start Date"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <DatePicker
                  disabledDate={(current) => {
                    return (
                      moment().add(-1, "days") >= current ||
                      moment().add(12, "month") <= current
                    );
                  }}
                  //defaultValue={moment()}
                  className="ml-2 custom-form-item-content custom-left-14"
                  format={dateFormat}
                  allowClear={false}
                />
              </Form.Item>
            </div>
            <div style={{ width: "45%", display: "inline-block" }}>
              <Form.Item
                name="booking_date_end"
                label="End Date"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <DatePicker
                  disabledDate={(current) => {
                    return (
                      moment().add(-1, "days") >= current ||
                      moment().add(12, "month") <= current ||
                      moment(form.getFieldValue().booking_date).add(
                        30,
                        "days"
                      ) <= current ||
                      moment(form.getFieldValue().booking_date).add(
                        -1,
                        "days"
                      ) >= current
                    );
                  }}
                  className="ml-2 custom-form-item-content custom-left-14"
                  format={dateFormat}
                  allowClear={false}
                />
              </Form.Item>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "45%", display: "inline-block" }}>
              <Form.Item
                label="Start Time"
                name="from"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Select
                  className="custom-form-item-content"
                  showSearch
                  onChange={(e) => setFromTime(e)}
                  placeholder="Select a start time"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {timeIntervalsvalues?.map((pp, k) => (
                    <>
                      <Select.Option key={pp.value} value={pp.value}>
                        {pp.label}
                      </Select.Option>
                    </>
                  ))}
                </Select>
              </Form.Item>
            </div>
            {(fromTime || fromTime === 0) && (
              <div style={{ width: "45%", display: "inline-block" }}>
                <Form.Item
                  label="End Time"
                  name="to"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Select
                    className="custom-form-item-content custom-left-4"
                    showSearch
                    placeholder="Select a end time"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {timeIntervalsvalues
                      ?.filter((a) => a.value > fromTime)
                      ?.map((pp, k) => (
                        <>
                          <Select.Option key={pp.value} value={pp.value}>
                            {pp.label}
                          </Select.Option>
                        </>
                      ))}
                  </Select>
                </Form.Item>
              </div>
            )}
          </div>

          {slot && (
            <Form.Item
              label="Court"
              name="name"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Select
                showSearch
                disabled={disabled}
                placeholder="Select a Court"
                optionFilterProp="children"
                onChange={(e)=>console.log(e)}
                // filterOption={(input, option) =>
                //   (option?.label ?? "")
                //     .toLowerCase()
                //     .includes(input.toLowerCase())
                // }
              >
                {slot?.map((mval, index) => (
                  <Option key={[mval?._id, mval?.Date]} value={mval?._id}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span className="dropdown-value">
                        <span className="dropdown-tag">Court: </span>
                        {mval?.name}
                      </span>
                      <span className="dropdown-value">
                        <span className="dropdown-tag">Date: </span>
                        {mval?.Date}
                      </span>
                      <span className="dropdown-value">
                        <span className="dropdown-tag">Time: </span>
                        {mval?.displayFrom}-{mval?.displayTo}
                      </span>
                      <span
                        style={{ marginRight: "50px" }}
                        className="dropdown-value"
                      >
                        <span className="dropdown-tag">Rate: $</span>
                        {mval?.prime_rate || mval?.regular_rate}
                      </span>
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <div className="form-title">Player Information</div>
          <Form.Item
            label="Player Name"
            name="player_name"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input placeholder="Enter a player name" disabled={disabled} />
          </Form.Item>
          <Form.Item
            label="Player Email ID"
            name="player_emailid"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input placeholder="Enter a player email" disabled={disabled} />
          </Form.Item>
          <Form.Item
            label="Player Phone Number"
            name="player_mobile"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input
              placeholder="Enter a player phone number"
              disabled={disabled}
            />
          </Form.Item>
          <Button
            style={{
              float: "right",
              backgroundColor: "#1a73e8",
              color: "white",
            }}
            disabled={true}
          >
            Book Now
          </Button>
        </Form>
      </div>
    </>
  );
};

export default FacilityFormComponent;
