import React, { useState, useEffect, useCallback } from 'react'
import { List, Form, AutoComplete, Table, Input, Button, Tag } from 'antd'
import { getFullName } from '../../../../utils';
import NumberInput from '../../../../common/NumberInput'
import { LoadingOutlined, UserOutlined } from '@ant-design/icons';
import { debounce } from 'lodash'
import apiClient from '../../../../services/axios'
import ApiConfig from '../../../../services/ApiConfig'
import moment from 'moment'


const FormElements = ({ cVisibleInfo, currentItem, onFinishApi }) => {

  const [form] = Form.useForm();
  const [searching, setSearching] = useState(false);
  const [players, setPlayers] = useState([]);
  const [owner, setOwner] = useState(null)
  const [selectedType, setselectedType] = useState(null)
  const [currentPlayerId, setcurrentPlayerId] = useState(null)
  const [CreditLogRes, setCreditLogRes] = useState(null)
  const [showLogs, setShowLogs] = useState(false)
  const [showFormItem, setshowFormItem] = useState(false)
  const [userInfo, setuserinfo] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await apiClient.get(ApiConfig.users.getUser);
        setuserinfo(response?.data?.userList)
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [])



  useEffect(() => {
    if (cVisibleInfo) {
      setShowLogs(false)
      setshowFormItem(false)
    }

  }, [cVisibleInfo])

  useEffect(() => {
    const valueData = currentItem?.player?.email
    const tmp = players.filter(e => valueData.indexOf(e.emailid) >= 0)[0];
    setcurrentPlayerId(tmp?._id)
  }, [currentItem.player.email, players])


  useEffect(() => {
    if (currentItem) {
      setcurrentPlayerId(currentItem?.player?._id)
      setPlayers([...[currentItem?.player]])
      const ownerNm = `${getFullName(currentItem?.player?.name)}${currentItem?.player?.name}<${currentItem?.player?.email}>`;
      form.setFieldsValue({
        players: currentItem.player?._id,
      })
      setOwner(ownerNm);
    } else {
      // else
    }
    // eslint-disable-next-line
  }, [currentItem])

  const getFormattedDate = (dateTime) => {
    return moment(dateTime).format('MMM D, YYYY')
  }

  const onFinishData = (values) => {
    const payload = {
      "players": values?.players,
      "credit_value": values?.credit_value,
      "type": selectedType
    }
    onFinishApi(payload)
  }

  const logColumns = [
    {
      title: "Date",
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text) => getFormattedDate(text),
    },
    {
      title: "Description",
      render: (record) => {
        return (
          <span>
            {record?.credit_type === 'add' && (
              <>
                <Tag style={{ cursor: 'pointer' }} color="#4BB543">{`$${record?.value / 100} credit ${record?.credit_type}ed`}</Tag>
              </>)}
            {record?.credit_type === 'remove' && (
              <>
                <Tag style={{ cursor: 'pointer' }} color="#e40">{`$${record?.value / 100} credit ${record?.credit_type}d`}</Tag>
              </>)}
            {record?.credit_type === 'used' && (
              <>
                <Tag style={{ cursor: 'pointer' }} color="orange">{`$${record?.value / 100} credit ${record?.credit_type}`}</Tag>
              </>)}
          </span>
        )
      },
    },
    {
      title: "User",
      render: (record) => getplayerName(record?.credit_type, record?.user),
    },
  ]


  const onSelectPlayer = (value = '') => {
    const tmp = players.filter(e => value.indexOf(e.emailid) >= 0)[0];
    setcurrentPlayerId(tmp?._id)
  };

  const getplayerName = (value1, value2) => {
    if (value1 === 'used') {
      const tmp = players?.filter(e => e?._id === value2)[0];
      return tmp?.name
    }
    else {
      const tmp = userInfo?.filter(e => e?._id === value2)[0];
      return tmp?.first_name + ' ' + tmp?.last_name
    }
  }

  useEffect(() => {
    if (currentPlayerId) {
      const fetchData = async () => {
        const response = await apiClient.patch(`${ApiConfig.credit.GetCreditLogs}/${currentPlayerId}`);
        setShowLogs(true)
        if (response?.data?.balance !== null) {
          form.setFieldsValue({
            credit_balance: response?.data?.balance
          })
        }
        else {
          form.setFieldsValue({
            credit_balance: 0
          })
        }
        setCreditLogRes(response?.data?.logInfo)
      }
      fetchData()
        .catch(console.error);
    }
  }, [currentPlayerId, form])

  const geOptions = (options = []) => {
    return options.map(opt => {
      return {
        value: `${getFullName(opt)}<${opt?.emailid} | ${opt?.mobile}>`,
        label: (
          <List.Item>
            <List.Item.Meta
              avatar={!opt?.profile_pic ? <UserOutlined /> : <img style={{ width: "30px" }} src="imgs/avatar_default.png" alt="" />}
              title={getFullName(opt)}
              description={opt?.emailid + ' | ' + opt?.mobile}
            />
          </List.Item>
        )
      }
    })
  }

  // eslint-disable-next-line
  const debouncedSearch = useCallback(
    debounce((nextValue, type) => handleSearch(nextValue, type), 500),
    [], // will be created only once initially
  );

  const handleSearch = async (value = '', type) => {
    if (value.length < 2) {
      if (type === 2) {
        setPlayers([...[]])
      }
      return;
    };
    try {
      setSearching(true);
      const { data } = await apiClient.get(`${ApiConfig.player.playerSearch}?q=${value}`);
      setPlayers([...data]);
      setSearching(false);
    } catch (error) {
      setSearching(false);
    }
  }

  const showCreditBalance = (values) => {
    setselectedType(values)
    setshowFormItem(true)
    form.setFieldsValue({
      credit_value: '',
    })
  }

  useEffect(() => {
    if (players) {
      form.setFieldsValue({
        players: players[0]?._id,
      })
    }
  }, [form, players])



  return (
    <div className='form-elements'>
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={onFinishData}
      >
        <Form.Item className='loader-form-block'>
          <Form.Item
            name="players"
            className='with-helper'
            label={<>Credit Account <div className='helper-text'>Search for the account you want to add credit</div></>}
            rules={[{ required: true, message: "This field is required" }]}
          >
            <AutoComplete
              disabled
              options={geOptions(players)}
              onSelect={onSelectPlayer}
              onSearch={(e) => debouncedSearch(e, 2)}
              value={owner}
              onBlur={(e) => {
                if (!e.target.value && currentItem?.player) {
                  setPlayers([...[currentItem?.player]])
                  setOwner(`${getFullName(currentItem?.player.name)}<${currentItem?.player?.email} | ${currentItem?.player?.mobile}>`)
                }
              }}
              onChange={(e) => setOwner(e)}
              popupClassName="mobile-autocomplete"
            >
              <Input placeholder="Search account by name or email" />
            </AutoComplete>
            {searching && (
              <Form.Item className='loader-form-item' noStyle>
                <span className="input-loader">
                  <LoadingOutlined />
                </span>
              </Form.Item>
            )}
          </Form.Item>
        </Form.Item>
        {showLogs && (
          <>
            <div className='helper-text'><b>Credit Logs</b></div>
            <div className="scrollable-div mb-2">
              <Table
                className='credit-logs-table'
                loading={loading}
                pagination={false}
                dataSource={CreditLogRes}
                columns={logColumns}
              />
              {/* {CreditLogRes?.map((info, index) => {
                return (
                  <div className="logs-style">
                    {`$${info?.value / 100} credit created by ${getplayerName(info?.user)} on ${getFormattedDate(info?.created_at)}`}
                  </div>
                )
              })} */}
            </div>

            <Form.Item
              name="credit_balance"
              label="Credit Balance"
              rules={[{ required: true, message: "Required" }]}
            >
              <NumberInput
                size="large"
                placeholder="Amount"
                addonBefore="$"
                max={999}
                readOnly
              />
            </Form.Item>
          </>
        )}
        {players?.length > 0 && (
          <>
            <Button
              style={{
                backgroundColor: '#f55322',
                border: '1px solid #f55322',
                marginRight: '5px',
                marginLeft: '5px'
              }}
              hidden={showFormItem}
              type="primary"
              onClick={() => showCreditBalance('remove')}
              className="apply-credit-btn"
            >
              Remove Credit
            </Button>
            <Button
              style={{
                backgroundColor: '#0c7f14',
                border: '1px solid #0c7f14',
                marginLeft: '5px',
                marginRight: '5px'
              }}
              hidden={showFormItem}
              type="primary"
              onClick={() => showCreditBalance('add')}
              className="apply-credit-btn"
            >
              Add Credit
            </Button>
          </>)}

        {showFormItem === true && (
          <>
            <Form.Item
              name="credit_value"
              className='form-item-credit-value'
              label="Credit Value"
              rules={[{ required: true, message: "Required" }]}
            >
              <NumberInput
                size="large"
                placeholder="Amount"
                addonBefore="$"
                max={999}
              />
            </Form.Item>

            <div className="form-footer">
              <Button type="primary" htmlType="submit">Update</Button>
            </div>
          </>)}
      </Form>
    </div>
  )
}

export default (FormElements)