import React from 'react'
// import $ from 'jquery';
import Widget1 from '../../components/AllInApp/Dashboard'
import SectionTitle from '../../components/AllInApp/section-title'
import { FiActivity, FiUsers, FiExternalLink, FiClock } from 'react-icons/fi'


const Index = () => {
  return (
    <>
      <SectionTitle title="Dashboard" />
      <div className="flex flex-col lg:flex-row w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4">
        {/*widget*/}
        <div className="w-full lg:w-1/4">
          <Widget1
            title="Basket Ball Courts"
            description={6}
            right={
              <FiUsers size={6} className="stroke-current text-grey-500" />
            }
          />
        </div>
        {/*widget*/}
        <div className="w-full lg:w-1/4">
          <Widget1
            title="Shooting Machines"
            description={3}
            right={
              <FiActivity size={3} className="stroke-current text-grey-500" />
            }
          />
        </div>
        {/*widget*/}
        <div className="w-full lg:w-1/4" hidden>
          <Widget1
            title="Sophos License"
            description={85}
            right={
              <FiExternalLink
                size={24}
                className="stroke-current text-grey-500"
              />
            }
          />
        </div>
        {/*widget*/}
        <div className="w-full lg:w-1/4" hidden>
          <Widget1
            title="Cylance License"
            description={287}
            right={
              <FiClock size={24} className="stroke-current text-grey-500" />
            }
          />
        </div>
        <div className="w-full lg:w-1/4" hidden>
          <Widget1
            title="Avanan License"
            description={59}
            right={
              <FiClock size={24} className="stroke-current text-grey-500" />
            }
          />
        </div>
      </div>

    </>
  )
}
export default Index
