import React, { useEffect } from 'react'
import { Button, Form, Input } from 'antd'
// import moment from "moment";

const PersonalComponent = ({ apiData, onSubmit }) => {

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      "first_name": apiData?.first_name,
      "last_name": apiData?.last_name,

    })
  }, [form, apiData])

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        requiredMark={false}
      >
        <div className="flex-list">
          <Form.Item
            name="first_name"
            label="First Name"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input maxLength={25} placeholder="Enter the first name" />
          </Form.Item>
          <Form.Item
            name="last_name"
            label="Last Name"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input maxLength={25} placeholder="Enter the last name" />
          </Form.Item>
        </div>
        <div className="form-footer" style={{ textAlign: "left" }}>
          <Button type="primary" className="h40" htmlType="submit">Update</Button>
        </div>
      </Form>
    </>
  )
}

export default (PersonalComponent)