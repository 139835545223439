import React, { useState, useEffect } from 'react'
import Item from './item'
import store from 'store'
import Logo from './logo'
import '../../css/components/left-sidebar-1.css'
import getMenuItems from '../../services/menu'

const Sidebar = () => {

  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    (async () => {
      // const data = store.get('user.data');
      const data2 = store.get('user.loggedInRole');
      const menus = getMenuItems();
      const tmp = menus[0]?.items?.filter(e => e?.roles?.indexOf(data2) >= 0);
      menus[0].items = tmp;
      setMenuItems([...menus])
    })();
  }, [])



  return (
    <div className="left-sidebar left-sidebar-1">
      <Logo />
      <div className="sidbar-items">
        {menuItems.map((menu, i) => (
          <div key={i}>
            <ul>
              {menu.items.map((l0, a) => (
                <li key={a} className="l0">
                  <Item {...l0} />
                  <ul>
                    {l0.items.map((l1, b) => (
                      <li key={b} className="l1">
                        <Item {...l1} />
                        <ul>
                          {l1.items.map((l2, c) => (
                            <li key={c} className="l2">
                              <Item {...l2} />
                              <ul>
                                {l2.items.map((l3, d) => (
                                  <li key={d} className="l3">
                                    <Item {...l3} />
                                    <ul>
                                      {l3.items.map((l4, e) => (
                                        <li key={e} className="l4">
                                          <Item {...l4} />
                                        </li>
                                      ))}
                                    </ul>
                                  </li>
                                ))}
                              </ul>
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Sidebar
