import React, { useEffect } from 'react'
import { Button, Form, Input } from 'antd'



const TypeComponent = ({ cVisibleInfo, onSubmit }) => {

  const [form] = Form.useForm();

  useEffect(() => {
    if (cVisibleInfo === true) {
      form.setFieldsValue({
        type_name: '',
      })
    }
  }, [form, cVisibleInfo])

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        requiredMark={false}
      >
        <div className="flex-list">
          <Form.Item
            name="type_name"
            label="Type Name"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input maxLength={25} placeholder="Enter the type name" />
          </Form.Item>
        </div>

        {cVisibleInfo && <div className="form-footer" style={{ textAlign: "center" }}>
          <Button type="primary" className="h40" htmlType="submit">Add Type</Button>
        </div>}
      </Form>
    </>
  )
}

export default (TypeComponent)