import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Form, Input } from 'antd'
import { v4 as uuidV4 } from 'uuid'
import store from 'store'
import Logo from '../../components/login/logo'
import Footer from '../../components/login/footer'
import apiClient from '../../services/axios'
import ApiConfig from '../../services/ApiConfig'

const LoginPage = () => {
  let history = useHistory()

  const LoginForm = () => {

    const [loading, setLoading] = useState(false);
    const onSubmit = async (values) => {
      setLoading(true);
      const clientId = localStorage.getItem('device-id')
      if (!clientId) {
        const uniqueId = uuidV4()
        localStorage.setItem('device-id', uniqueId)
      }
      values.uuid = localStorage.getItem('device-id');
      try {
        const response = await apiClient.post(ApiConfig.auth.login, values);
        const { access_token: accessToken, user } = response.data;
        store.set(`user.data`, user);
        store.set(`user.accessToken`, accessToken);
        store.set(`user.loggedInRole`, user.roles);
        if (response?.data?.user?.roles==='Super Admin') {
          history.replace('/dashboard')
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }

    return (
      <div className="flex flex-col">
        <Form
          layout="vertical"
          requiredMark={false}
          onFinish={onSubmit}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input placeholder="Enter your email" />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "This field is required" },
              // { min: 8, message: "Password must be at least 8 characters" }
            ]}
          >
            <Input type="password" placeholder="Enter your password" />
          </Form.Item>
          <Form.Item noStyle>
            <Button loading={loading} htmlType="submit" type="primary">Login</Button>
          </Form.Item>
        </Form>
      </div>
    )
  }


  useEffect(() => {
    const token = store.get('user.accessToken');
    if (token) {
      // history.replace('/dashboard')
    }
  }, [])

  return (
    <>
      <div className="w-full flex flex-row h-screen overflow-hidden auth-page-2">
        <div className="hidden lg:flex lg:flex-col w-3/4 text-white p-8 items-start justify-between relative bg-login-2">
          <Logo />
        </div>
        <div className="w-full lg:w-1/2 bg-white p-8 lg:p-24 flex flex-col items-start justify-center">
          <p className="text-2xl font-bold text-blue-500 mb-4">
            Login to All IN App
          </p>
          <LoginForm />
          <div className="w-full">
            <br />
            <span>
              <Link className="link" to="/forgot-password">
                Forgot password?
              </Link>
            </span>
          </div>
          <div className='footer-elem'>
            <Footer />
          </div>
        </div>
      </div>
    </>
  )
}

export default LoginPage
